import { Button, Stack, Typography } from '@mui/material';
import { useForm, useFormContext } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { useEffect, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, RHFTextField } from '#/components/shared/hook-form';
import useLocales from '#/lib/hooks/useLocales';
import { Notary } from '#/types/importantInformation';
import {
  invalidateImportantInformationQueries,
  useAddNotary,
  useGetNotaryById,
  useUpdateNotary,
} from '#/api/importantInformation';
import { notarySchema } from '#/components/pages/ImportantInformation/steps/schemas';

type Props = {
  editID?: boolean | number;
  resetEnableForm?: () => void;
  resetEditMode?: () => void;
  resetAddMore?: () => void;
};

export default function NotaryForm({
  resetEnableForm,
  resetEditMode,
  resetAddMore,
  editID,
}: Props) {
  const { translate } = useLocales();

  const { mutateAsync: addNotary, isLoading: adding } = useAddNotary();
  const { data: notary } = useGetNotaryById(Number(editID), !!editID);
  const { mutateAsync: updateNotary, isLoading: updating } = useUpdateNotary(
    Number(editID)
  );

  const { watch } = useFormContext();
  const serviceID = watch('id');

  const defaultValues = useMemo(
    () => ({
      important_information: serviceID,
      name: notary?.name || '',
      email: notary?.email || '',
    }),
    [notary, watch('important_information')]
  );

  const methods = useForm<Notary>({
    // @ts-ignore
    resolver: yupResolver(notarySchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isDirty },
    reset,
  } = methods;

  const disableSubmit = !isDirty;

  const onSubmit = async (data: Notary) => {
    if (editID) {
      await updateNotary(data).then(() => {
        invalidateImportantInformationQueries.getNotary();
        invalidateImportantInformationQueries.getNotaryById(Number(editID));
        resetEditMode?.();
        resetEnableForm?.();
      });
    } else {
      await addNotary(data).then(() => {
        invalidateImportantInformationQueries.getNotary();
        resetAddMore?.();
        resetEnableForm?.();
      });
    }
  };

  useEffect(() => {
    if (notary || serviceID) {
      reset(defaultValues);
    }
  }, [notary, serviceID]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <RHFTextField
          name="name"
          label={String(translate('global.formLabels.name'))}
        />
        <RHFTextField
          name="email"
          label={String(translate('global.formLabels.emailAddress'))}
        />
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        sx={{ marginTop: 2 }}
      >
        <Button
          variant="contained"
          color="inherit"
          onClick={() => {
            resetEnableForm?.();
            resetEditMode?.();
            resetAddMore?.();
          }}
        >
          <Typography variant="subtitle2" fontWeight="light">
            {String(translate('global.cancel'))}
          </Typography>
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          loading={adding || updating}
          disabled={disableSubmit}
          onClick={(e) => {
            e.preventDefault();
            handleSubmit(onSubmit)();
          }}
        >
          <Typography variant="subtitle2" fontWeight="light">
            {String(translate('global.save'))}
          </Typography>
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
