import { useState, useEffect, useCallback, RefObject } from 'react';

const useIsTextTruncated = (ref: RefObject<HTMLElement>): boolean => {
  const [isTruncated, setIsTruncated] = useState(false);

  const checkIsTruncated = useCallback(() => {
    if (ref.current) {
      setIsTruncated(ref.current.scrollWidth > ref.current.clientWidth);
    }
  }, [ref]);

  useEffect(() => {
    checkIsTruncated();
    window.addEventListener('resize', checkIsTruncated);
    return () => {
      window.removeEventListener('resize', checkIsTruncated);
    };
  }, [checkIsTruncated]);

  return isTruncated;
};

export default useIsTextTruncated;
