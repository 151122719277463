import queryClient from '#/api/query-client';
import { useDelete, useGet, usePatch, usePost } from '#/api/reactQuery';
import {
  ImportantInformationService,
  ThreeA,
  Notary,
  InsurancePolicy,
  InsuranceResponse,
  ThreeAResponse,
  NotaryResponse,
} from '#/types/importantInformation';

const BASE_URL = '/digital-footprint/important-information';

export const ImportantInformationEndpoints = {
  createImportantInformation: () => `${BASE_URL}/`,
  getImportantInformation: () => `${BASE_URL}/`,
  add3A: () => `${BASE_URL}/pension3a/`,
  get3A: () => `${BASE_URL}/pension3a/`,
  get3AById: (id: number | string | undefined) =>
    `${BASE_URL}/pension3a/${id}/`,
  update3A: (id: number | string | undefined) => `${BASE_URL}/pension3a/${id}/`,
  delete3A: (id: number | string | undefined) => `${BASE_URL}/pension3a/${id}/`,
  addNotary: () => `${BASE_URL}/notaryfiduciary/`,
  getNotary: () => `${BASE_URL}/notaryfiduciary/`,
  getNotaryById: (id: number | string | undefined) =>
    `${BASE_URL}/notaryfiduciary/${id}/`,
  updateNotary: (id: number | string | undefined) =>
    `${BASE_URL}/notaryfiduciary/${id}/`,
  deleteNotary: (id: number | string | undefined) =>
    `${BASE_URL}/notaryfiduciary/${id}/`,
  addInsurance: () => `${BASE_URL}/insurancepolicy/`,
  getInsurance: () => `${BASE_URL}/insurancepolicy/`,
  getInsuranceById: (id: number | string | undefined) =>
    `${BASE_URL}/insurancepolicy/${id}/`,
  updateInsurance: (id: number | string | undefined) =>
    `${BASE_URL}/insurancepolicy/${id}/`,
  deleteInsurance: (id: number | string | undefined) =>
    `${BASE_URL}/insurancepolicy/${id}/`,
};

export const invalidateImportantInformationQueries = {
  getImportantInformation: () => {
    queryClient
      .invalidateQueries(
        ImportantInformationEndpoints.getImportantInformation()
      )
      .then((r) => r);
  },
  get3A: () => {
    queryClient
      .invalidateQueries(ImportantInformationEndpoints.get3A())
      .then((r) => r);
  },
  get3AById: (id: number | string | undefined) => {
    queryClient
      .invalidateQueries(ImportantInformationEndpoints.get3AById(id))
      .then((r) => r);
  },
  getNotary: () => {
    queryClient
      .invalidateQueries(ImportantInformationEndpoints.getNotary())
      .then((r) => r);
  },
  getNotaryById: (id: number | string | undefined) => {
    queryClient
      .invalidateQueries(ImportantInformationEndpoints.getNotaryById(id))
      .then((r) => r);
  },
  getInsurance: () => {
    queryClient
      .invalidateQueries(ImportantInformationEndpoints.getInsurance())
      .then((r) => r);
  },
  getInsuranceById: (id: number | string | undefined) => {
    queryClient
      .invalidateQueries(ImportantInformationEndpoints.getInsuranceById(id))
      .then((r) => r);
  },
};

export const useCreateImportantInformation = () =>
  usePost<ImportantInformationService, ImportantInformationService>(
    ImportantInformationEndpoints.createImportantInformation()
  );

export const useGetImportantInformation = (
  invitation_id?: number | string | undefined
) =>
  useGet<ImportantInformationService>(
    ImportantInformationEndpoints.getImportantInformation(),
    {
      invitation_id,
    }
  );

export const useAdd3A = () =>
  usePost<ThreeA, ThreeA>(ImportantInformationEndpoints.add3A());

export const useGet3A = (invitation_id?: number | string) =>
  useGet<ThreeAResponse>(ImportantInformationEndpoints.get3A(), {
    invitation_id,
  });

export const useGet3AById = (
  id: number | string | undefined,
  enabled: boolean
) =>
  useGet<ThreeA>(
    ImportantInformationEndpoints.get3AById(id),
    {},
    {
      enabled,
    }
  );

export const useUpdate3A = (id: number | string | undefined) =>
  usePatch<ThreeA, ThreeA>(ImportantInformationEndpoints.update3A(id));

export const useDelete3A = (id: number | string | undefined) =>
  useDelete(ImportantInformationEndpoints.delete3A(id));

export const useAddNotary = () =>
  usePost<Notary, Notary>(ImportantInformationEndpoints.addNotary());

export const useGetNotary = (invitation_id?: string | number) =>
  useGet<NotaryResponse>(ImportantInformationEndpoints.getNotary(), {
    invitation_id,
  });

export const useGetNotaryById = (
  id: number | string | undefined,
  enabled: boolean
) =>
  useGet<Notary>(
    ImportantInformationEndpoints.getNotaryById(id),
    {},
    { enabled }
  );

export const useUpdateNotary = (id: number | string | undefined) =>
  usePatch<Notary, Notary>(ImportantInformationEndpoints.updateNotary(id));

export const useDeleteNotary = (id: number | string | undefined) =>
  useDelete(ImportantInformationEndpoints.deleteNotary(id));

export const useAddInsurance = () =>
  usePost<InsurancePolicy, InsurancePolicy>(
    ImportantInformationEndpoints.addInsurance()
  );

export const useGetInsurance = (invitation_id?: number | string) =>
  useGet<InsuranceResponse>(ImportantInformationEndpoints.getInsurance(), {
    invitation_id,
  });

export const useGetInsuranceById = (
  id: number | string | undefined,
  enabled: boolean
) =>
  useGet<InsurancePolicy>(
    ImportantInformationEndpoints.getInsuranceById(id),
    {},
    {
      enabled,
    }
  );

export const useUpdateInsurance = (id: number | string | undefined) =>
  usePatch<InsurancePolicy, InsurancePolicy>(
    ImportantInformationEndpoints.updateInsurance(id)
  );

export const useDeleteInsurance = (id: number | string | undefined) =>
  useDelete(ImportantInformationEndpoints.deleteInsurance(id));
