import { FormLabel, Typography } from '@mui/material';
import { RHFTextField } from '#/components/shared/hook-form';

type Props = {
  name: string;
  label: string;
  placeholder?: string;
  disabled?: boolean;
};

export default function InputLabelGroup({
  label,
  name,
  placeholder,
  disabled,
}: Props) {
  return (
    <FormLabel
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 1,
        gap: 1,
      }}
    >
      <Typography color="text.primary">{label}</Typography>
      <RHFTextField name={name} label={placeholder} disabled={disabled} />
    </FormLabel>
  );
}
