import { useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import useLocales from '#/hooks/useLocales';
import ThreeAForm from '#/components/pages/ImportantInformation/steps/FinancialInformations/3a/3a-form';
import ThreeADetails from '#/components/pages/ImportantInformation/steps/FinancialInformations/3a/3a-details';
import { useGet3A } from '#/api/importantInformation';
import useLocalStorage from '#/hooks/useLocalStorage';

export default function ThreeA() {
  const [addMore, setAddMore] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean | number | undefined>();

  const { translate } = useLocales();
  const { state } = useLocation();
  const [ownerID] = useLocalStorage('ownerID', state?.ownerID);

  const { data: threeAs } = useGet3A(ownerID || undefined);

  const isInitial = threeAs?.results?.length === 0;

  const handleAddMore = () => setAddMore(true);
  const handleEditMode = (id: number) => setEditMode(id);

  if (ownerID) {
    if (threeAs?.results?.length === 0) {
      return (
        <Typography variant="subtitle1" fontWeight="light">
          {String(translate('global.noData'))}
        </Typography>
      );
    }
    return threeAs?.results?.map((threeA, i) => (
      <ThreeADetails
        key={i}
        threeA={threeA}
        handleEditMode={handleEditMode}
        readOnly
      />
    ));
  }

  return addMore || isInitial || editMode ? (
    <ThreeAForm
      editMode={editMode}
      resetEditMode={() => setEditMode(false)}
      resetAddMore={() => setAddMore(false)}
    />
  ) : (
    <>
      {threeAs?.results?.map((threeA, i) => (
        <ThreeADetails
          key={i}
          threeA={threeA}
          handleEditMode={handleEditMode}
        />
      ))}
      <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2 }}>
        <Button size="small" variant="contained" onClick={handleAddMore}>
          <Typography variant="subtitle2" fontWeight="light">
            {String(translate('global.addMore'))}
          </Typography>
        </Button>
      </Stack>
    </>
  );
}
