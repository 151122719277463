import { useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import InsurancePolicyForm from '#/components/pages/ImportantInformation/steps/FinancialInformations/insurance-policies/insurance-policy-form';
import InsurancePolicyDetails from '#/components/pages/ImportantInformation/steps/FinancialInformations/insurance-policies/insurance-policy-details';
import useLocales from '#/hooks/useLocales';
import { useGetInsurance } from '#/api/importantInformation';
import useLocalStorage from '#/hooks/useLocalStorage';

export default function InsurancePolicies() {
  const [addMore, setAddMore] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean | number | undefined>();

  const { translate } = useLocales();
  const { state } = useLocation();
  const [ownerID] = useLocalStorage('ownerID', state?.ownerID);

  const { data: insurances } = useGetInsurance(ownerID || undefined);

  const isInitial = insurances?.results?.length === 0;

  const handleAddMore = () => setAddMore(true);
  const handleEditMode = (id: number) => setEditMode(id);

  if (ownerID) {
    if (insurances?.results?.length === 0) {
      return (
        <Typography variant="subtitle1" fontWeight="light">
          {String(translate('global.noData'))}
        </Typography>
      );
    }
    return insurances?.results?.map((insurance, i) => (
      <InsurancePolicyDetails
        key={i}
        insurance={insurance}
        handleEditMode={handleEditMode}
        readOnly
      />
    ));
  }

  return addMore || isInitial || editMode ? (
    <InsurancePolicyForm
      editMode={editMode}
      resetEditMode={() => setEditMode(false)}
      resetAddMore={() => setAddMore(false)}
    />
  ) : (
    <>
      {insurances?.results?.map((insurance, i) => (
        <InsurancePolicyDetails
          key={i}
          insurance={insurance}
          handleEditMode={handleEditMode}
        />
      ))}
      <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2 }}>
        <Button size="small" variant="contained" onClick={handleAddMore}>
          <Typography variant="subtitle2" fontWeight="light">
            {String(translate('global.addMore'))}
          </Typography>
        </Button>
      </Stack>
    </>
  );
}
