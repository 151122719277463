import {
  Box,
  Container,
  Step,
  StepLabel,
  Stepper,
  Card,
  Divider,
  Stack,
  Typography,
  Button,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo } from 'react';
import { LoadingButton } from '@mui/lab';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import IntroHead from '#/components/shared/IntroHead';
import useLocales from '#/hooks/useLocales';
import { FormProvider } from '#/components/shared/hook-form';
import useStepper from '#/hooks/useStepper';
import { stepsData } from '#/components/pages/ImportantInformation/stepsData';
import { QontoConnector } from '#/components/pages/LivingWill/Steps/styles';
// import useServiceStats from '#/hooks/useServiceStats';
import QontoStepIcon from '#/components/pages/LivingWill/Steps/QontoStepIcon';
import { ImportantInformationService } from '#/types/importantInformation';
import {
  invalidateImportantInformationQueries,
  useCreateImportantInformation,
  useGetImportantInformation,
} from '#/api/importantInformation';
import Iconify from '#/components/shared/ui/Iconify';
import {
  schemaValues,
  stepOneSchema,
  stepThreeSchema,
  stepTwoSchema,
} from '#/components/pages/ImportantInformation/schemaValidations';
import useLocalStorage from '#/hooks/useLocalStorage';

export default function ImportantInformationForm() {
  const { translate } = useLocales();
  const { state } = useLocation();

  const [ownerID] = useLocalStorage('ownerID', state?.ownerID);

  const { mutateAsync: createII } = useCreateImportantInformation();
  const { data: initialValues } = useGetImportantInformation(
    ownerID || undefined
  );

  // const { important_information } = useServiceStats();
  const { activeStep, setActiveStep, handleBack, handleNext } = useStepper(
    false,
    'important_information',
    stepsData
  );

  const navigate = useNavigate();

  const defaultValues = useMemo(
    () => schemaValues(initialValues, activeStep),
    [initialValues, activeStep]
  );

  const schemaSteps = [stepOneSchema, stepTwoSchema, stepThreeSchema];

  const methods = useForm<ImportantInformationService>({
    resolver: schemaSteps[activeStep]
      ? yupResolver(schemaSteps[activeStep] as any)
      : undefined,
    defaultValues,
    reValidateMode: 'onChange',
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = async (data: ImportantInformationService) => {
    const savingToast = toast(String(translate('global.saving')), {
      icon: <Iconify icon="arcticons:fastsave" />,
    });
    try {
      await createII(data).then(() => {
        toast.dismiss(savingToast);
        if (activeStep === translatedStepsData.length - 1) {
          navigate('/dashboard/digitaler-nachlass');
        }
        handleNext();
        invalidateImportantInformationQueries.getImportantInformation();
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (initialValues || activeStep) {
      reset(defaultValues);
    }
  }, [initialValues, activeStep]);

  useEffect(
    () => () => {
      const submit = async () => {
        await handleSubmit(onSubmit)();
      };
      submit();
    },
    []
  );

  const translatedStepsData = stepsData.map((step, i) => ({
    ...step,
    label: translate(`importantInformation.stepperInfo.${i}.label`),
    title: translate(`importantInformation.stepperInfo.${i}.title`),
  }));

  const handleBackToDashboard = () => {
    navigate('/dashboard/digitaler-nachlass');
  };

  return (
    <Container maxWidth="lg">
      <Stack direction="row" justifyContent="center">
        <Button onClick={handleBackToDashboard} sx={{ width: 'fit-content' }}>
          <Typography>{String(translate('global.back'))}</Typography>
        </Button>
      </Stack>
      <IntroHead
        title={String(translate('importantInformation.mainTitle'))}
        description={String(translate('importantInformation.mainDescription'))}
        sx={{
          my: 3,
          mb: 9,
        }}
      />
      <Box>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<QontoConnector />}
            sx={{
              display: {
                xs: 'none',
                sm: 'flex',
              },
            }}
          >
            {translatedStepsData?.map((step, i) => (
              <Step
                key={`step-${step.label}`}
                onClick={() => {
                  if (i < activeStep) {
                    setActiveStep(i);
                  }
                  setActiveStep(i);
                }}
                sx={{
                  cursor: 'pointer',
                }}
              >
                <StepLabel StepIconComponent={QontoStepIcon}>
                  {String(step.label)}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <Card sx={{ p: 3, mt: 4 }}>
            {translatedStepsData[activeStep]?.content()}
            <Divider
              sx={{
                mt: 4,
              }}
            />
            <Stack
              direction={{
                xs: 'column',
                sm: 'row',
              }}
              justifyContent="flex-end"
              spacing={2}
              sx={{
                mt: 3,
              }}
            >
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                <Typography>{String(translate('global.back'))}</Typography>
              </Button>
              {activeStep === translatedStepsData.length - 1 && (
                <Button
                  type="submit"
                  variant="contained"
                  color="success"
                  sx={{
                    color: '#fff',
                  }}
                >
                  <Typography>{String(translate('global.done'))}</Typography>
                </Button>
              )}
              {activeStep !== translatedStepsData.length - 1 && (
                <LoadingButton
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  <Typography>{String(translate('global.next'))}</Typography>
                </LoadingButton>
              )}
            </Stack>
          </Card>
        </FormProvider>
      </Box>
    </Container>
  );
}
