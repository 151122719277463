import { FormHelperText, Stack, Typography } from '@mui/material';
import React, { useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import useLocales from '#/hooks/useLocales';
import { financialInformationQuestions } from '#/components/pages/ImportantInformation/stepsData';

export default function FinancialInformation() {
  const { translate } = useLocales();

  const { control } = useFormContext();

  const refs = useRef<{ [key: string]: HTMLElement | null }>({});

  const translatedQuestions = financialInformationQuestions.map(
    (question, i) => ({
      ...question,
      question: translate(
        `importantInformation.stepTwo.questions.${i}.question`
      ),
    })
  );

  return (
    <Stack
      sx={{
        '& > div': {
          marginBottom: '20px',
        },
      }}
    >
      {translatedQuestions.map((question) => (
        <div
          key={question.questionKey}
          ref={(el) => (refs.current[question.questionKey] = el)}
        >
          <Controller
            control={control}
            name={question.questionKey}
            render={({ fieldState: { error } }) => (
              <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
                <Typography variant="subtitle1">
                  {String(question.question)}
                </Typography>
                {!!error && (
                  <FormHelperText error={!!error} sx={{ mx: 0 }}>
                    {error && String(translate(error?.message))}
                  </FormHelperText>
                )}
              </Stack>
            )}
          />
          <Stack>
            {question.children && <>{question.children.content()}</>}
          </Stack>
        </div>
      ))}
    </Stack>
  );
}
