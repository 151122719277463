import * as Yup from 'yup';
import { ImportantInformationService } from '#/types/importantInformation';

export const stepOneSchema = Yup.object().shape({
  // passport_storage_location: Yup.string().required(
  //   'validations.importantInformation.passport_storage_location'
  // ),
  // identity_card_storage_location: Yup.string().required(
  //   'validations.importantInformation.identity_card_storage_location'
  // ),
  // residence_permit_storage_location: Yup.string().required(
  //   'validations.importantInformation.residence_permit_storage_location'
  // ),
  // family_book_storage_location: Yup.string().required(
  //   'validations.importantInformation.family_book_storage_location'
  // ),
  // birth_certificate_storage_location: Yup.string().required(
  //   'validations.importantInformation.birth_certificate_storage_location'
  // ),
});

export const stepTwoSchema = Yup.object().shape({
  // bank_documents_storage_location: Yup.string().required(
  //   'validations.importantInformation.bank_documents_storage_location'
  // ),
  ahv_number: Yup.string().test(
    'is-valid-ahv-number',
    'validations.importantInformation.ahv_number_format',
    (value) => {
      if (!value || value.startsWith('756.')) {
        return true; // If the field is empty or starts with '756.', don't trigger validation
      }
      const regex = /^756\.\d{4}\.\d{4}\.\d{2}$/;
      return regex.test(value);
    }
  ),
  // allows the field to be null or an empty string without triggering the regex validation
  // Uncomment the following lines if needed:
  // ahv_fund: Yup.string().required('validations.importantInformation.ahv_fund'),
  // pension_fund_organization: Yup.string().required(
  //   'validations.importantInformation.pension_fund_organization'
  // ),
});

export const stepThreeSchema = Yup.object().shape({
  trusted_person: Yup.string().required(
    'validations.importantInformation.trusted_person'
  ),
});

export const schemaValues = (
  initialValues: ImportantInformationService | undefined,
  activeStep: number
) => ({
  id: initialValues?.id ?? '',
  passport_storage_location: initialValues?.passport_storage_location ?? '',
  identity_card_storage_location:
    initialValues?.identity_card_storage_location ?? '',
  residence_permit_storage_location:
    initialValues?.residence_permit_storage_location ?? '',
  family_book_storage_location:
    initialValues?.family_book_storage_location ?? '',
  birth_certificate_storage_location:
    initialValues?.birth_certificate_storage_location ?? '',
  bank_documents_storage_location:
    initialValues?.bank_documents_storage_location ?? '',
  ahv_fund: initialValues?.ahv_fund ?? '',
  ahv_number: initialValues?.ahv_number ?? '',
  pension_fund_organization: initialValues?.pension_fund_organization ?? '',
  trusted_person: initialValues?.trusted_person ?? '',
  step: activeStep,
});
