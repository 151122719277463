import { Box, Stack } from '@mui/material';
import { useLocation } from 'react-router-dom';
import useLocales from '#/hooks/useLocales';
import InputLabelGroup from '#/components/shared/InputLabelGroup';
import useLocalStorage from '#/hooks/useLocalStorage';

export default function IdentificationDocumentsForm() {
  const { translate } = useLocales();
  const { state } = useLocation();

  const [ownerID] = useLocalStorage('ownerID', state?.ownerID);

  return (
    <Box
      sx={{
        '& > *': {
          my: 2,
        },
      }}
    >
      <Stack
        direction={{
          xs: 'column',
          sm: 'row',
        }}
        spacing={2}
      >
        <InputLabelGroup
          name="passport_storage_location"
          label={String(translate('global.formLabels.passportLocation'))}
          placeholder={String(translate('global.formLabels.storageLocation'))}
          disabled={ownerID}
        />
        <InputLabelGroup
          name="identity_card_storage_location"
          label={String(translate('global.formLabels.idCardLocation'))}
          placeholder={String(translate('global.formLabels.storageLocation'))}
          disabled={ownerID}
        />
      </Stack>
      <Stack
        direction={{
          xs: 'column',
          sm: 'row',
        }}
        spacing={2}
      >
        <InputLabelGroup
          name="residence_permit_storage_location"
          label={String(translate('global.formLabels.residencePermitLocation'))}
          placeholder={String(translate('global.formLabels.storageLocation'))}
          disabled={ownerID}
        />
        <InputLabelGroup
          name="family_book_storage_location"
          label={String(translate('global.formLabels.familyBookLocation'))}
          placeholder={String(translate('global.formLabels.storageLocation'))}
          disabled={ownerID}
        />
      </Stack>
      <Stack>
        <InputLabelGroup
          name="birth_certificate_storage_location"
          label={String(
            translate('global.formLabels.birthCertificateLocation')
          )}
          placeholder={String(translate('global.formLabels.storageLocation'))}
          disabled={ownerID}
        />
      </Stack>
    </Box>
  );
}
