import { Stack, Typography } from '@mui/material';
import React, { useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import useLocales from '#/hooks/useLocales';
import { trustedPersonQuestions } from '#/components/pages/ImportantInformation/stepsData';

export default function TrustedPerson() {
  const { translate } = useLocales();

  const { control } = useFormContext();

  const refs = useRef<{ [key: string]: HTMLElement | null }>({});

  const translatedQuestions = trustedPersonQuestions.map((question, i) => ({
    ...question,
    question: translate(
      `importantInformation.stepThree.questions.${i}.question`
    ),
  }));

  return (
    <Stack
      sx={{
        '& > div': {
          marginBottom: '20px',
        },
      }}
    >
      {translatedQuestions.map((question) => (
        <div
          key={question.questionKey}
          ref={(el) => (refs.current[question.questionKey] = el)}
        >
          <Controller
            control={control}
            name={question.questionKey}
            render={() => (
              <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
                <Typography variant="subtitle1">
                  {String(question.question)}
                </Typography>
                {/* {!!error && ( */}
                {/*  <FormHelperText error={!!error} sx={{ mx: 0 }}> */}
                {/*    {error && String(translate(error?.message))} */}
                {/*  </FormHelperText> */}
                {/* )} */}
              </Stack>
            )}
          />
          {question.children && <>{question.children.content()}</>}
        </div>
      ))}
    </Stack>
  );
}
