import { Button, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '#/components/shared/ui/Iconify';
import useLocales from '#/hooks/useLocales';
import { Notary } from '#/types/importantInformation';
import {
  invalidateImportantInformationQueries,
  useDeleteNotary,
} from '#/api/importantInformation';

type Props = {
  handleEditMode: (id: number) => void;
  notary: Notary;
  readOnly?: boolean;
};

export default function NotaryDetails({
  notary,
  handleEditMode,
  readOnly,
}: Props) {
  const { translate } = useLocales();

  const { mutateAsync: deleteNotary, isLoading: deleting } = useDeleteNotary(
    notary.id
  );

  const handleDelete = async () => {
    await deleteNotary(notary.id).then(() => {
      invalidateImportantInformationQueries.getNotary();
    });
  };

  return (
    <Stack
      direction={{
        xs: 'column',
        sm: 'row',
      }}
      sx={{ my: 2 }}
      justifyContent="space-between"
    >
      <Stack direction="row" spacing={2}>
        <Typography
          variant="subtitle1"
          fontWeight="light"
          sx={{ width: '160px' }}
          noWrap
        >
          {notary.name}
        </Typography>
        <Typography variant="subtitle1" fontWeight="light" noWrap>
          {notary.email}
        </Typography>
      </Stack>
      {!readOnly && (
        <Stack direction="row" spacing={1}>
          <Button
            size="small"
            variant="outlined"
            startIcon={
              <Iconify icon="octicon:pencil-16" sx={{ fontSize: '1.5rem' }} />
            }
            onClick={() => handleEditMode(notary.id)}
          >
            <Typography variant="subtitle2" fontWeight="light">
              {String(translate('global.edit'))}
            </Typography>
          </Button>
          <LoadingButton
            size="small"
            variant="outlined"
            color="error"
            startIcon={
              <Iconify icon="octicon:trash-16" sx={{ fontSize: '1.5rem' }} />
            }
            loading={deleting}
            onClick={handleDelete}
          >
            <Typography variant="subtitle2" fontWeight="light">
              {String(translate('global.delete'))}
            </Typography>
          </LoadingButton>
        </Stack>
      )}
    </Stack>
  );
}
