const pensionFunds = [
  {
    value: 'ah_meyer_personal_pension',
    label: 'A.H. Meyer & Cie AG - Personalvorsorgestiftung',
  },
  {
    value: 'ah_meyer_welfare_pension',
    label: 'A.H. Meyer & Cie AG - Wohlfahrts-Stiftung',
  },
  {
    value: 'abegg_university_zurich_pension',
    label:
      'Abegg-Stiftung zur Ergänzung der Witwen-, Waisen- und Pensionskasse der Professoren der Universität Zürich',
  },
  { value: 'abogado_1e_pension', label: 'Abogado 1e-Stiftung' },
  {
    value: 'accenture_executive_pension',
    label: 'Accenture Executive Pensionskasse',
  },
  {
    value: 'sairgroup_general_pension',
    label: 'Allgemeine Pensionskasse der SAirGroup',
  },
  {
    value: 'rieter_general_pension',
    label: 'Allgemeine Personalfürsorgestiftung der Maschinenfabrik Rieter AG',
  },
  { value: 'pkz_general_welfare', label: 'Allgemeiner PKZ-Fürsorge-Fonds' },
  {
    value: 'kistler_general_welfare',
    label:
      'Allgemeiner Wohlfahrtsfonds der Kistler Instrumente Aktiengesellschaft, Winterthur',
  },
  {
    value: 'allianz_pension_invest',
    label:
      'Allianz Pension Invest - Teilautonome Sammelstiftung für berufliche Vorsorge',
  },
  { value: 'alrivo_pension', label: 'ALRIVO Vorsorgestiftung' },
  {
    value: 'elsasser_staff_welfare',
    label: 'Angestellten-Fürsorgefonds der Elsässer & Cie AG',
  },
  {
    value: 'pfenninger_staff_welfare',
    label: 'Angestelltenfürsorge-Stiftung der Pfenninger & Cie. AG',
  },
  {
    value: 'bucher_staff_pension',
    label: 'Angestellten-Pensionskasse Bucher Schweiz',
  },
  { value: 'avanea_pension', label: 'Avanea Pensionskasse' },
  { value: 'avos_pension', label: 'Avos-Stiftung' },
  { value: 'axa_bvg_west', label: 'AXA BVG-Stiftung Westschweiz, Winterthur' },
  { value: 'axa_stiftung_1e', label: 'AXA Stiftung 1e, Winterthur' },
  {
    value: 'axa_professional_pension',
    label: 'AXA Stiftung Berufliche Vorsorge, Winterthur',
  },
  {
    value: 'axa_additional_pension',
    label: 'AXA Stiftung Zusatzvorsorge, Winterthur',
  },
  { value: 'bafidia_pension', label: 'Bafidia Pensionskasse Genossenschaft' },
  { value: 'baggenstos_pension', label: 'Baggenstos-Stiftung' },
  { value: 'bayer_pension', label: 'Bayer Pensionskasse Schweiz' },
  { value: 'swiss_life_bvg_pension', label: 'BVG-Sammelstiftung Swiss Life' },
  {
    value: 'falcon_private_bank_bvg',
    label: 'BVG-Stiftung der Falcon Private Bank in Liquidation',
  },
  { value: 'fbb_group_bvg', label: 'BVG-Stiftung der FBB-Gruppe, Bauma' },
  {
    value: 'gadola_holding_bvg',
    label: 'BVG-Stiftung der Gadola Holding Gruppe',
  },
  { value: 'hueppi_bvg', label: 'BVG-Stiftung der Hüppi-Unternehmungen' },
  {
    value: 'schroder_bvg',
    label: 'BVG-Stiftung der Schroder & Co Bank AG Zürich',
  },
  { value: 'sv_group_bvg', label: 'BVG-Stiftung der SV Group' },
  {
    value: 'bvk_personal_zurich',
    label: 'BVK Personalvorsorge des Kantons Zürich',
  },
  {
    value: 'charles_apotheloz_pension',
    label:
      'Charles Apothéloz-Stiftung Berufliche Vorsorge für Kulturschaffende (CAST)',
  },
  { value: 'citi_pension', label: 'Citi Pensionskasse' },
  {
    value: 'columna_client_invest',
    label: 'Columna Sammelstiftung Client Invest, Winterthur',
  },
  {
    value: 'columna_group_invest',
    label: 'Columna Sammelstiftung Group Invest, Winterthur',
  },
  {
    value: 'credit_suisse_2nd_pillar',
    label: 'CREDIT SUISSE Freizügigkeitsstiftung 2. Säule',
  },
  {
    value: 'credit_suisse_privilegia_3rd_pillar',
    label: 'CREDIT SUISSE PRIVILEGIA Vorsorgestiftung 3. Säule',
  },
  {
    value: 'dow_personal_pension',
    label: 'Dow Personalvorsorgestiftung (Schweiz)',
  },
  { value: 'durach_pension', label: 'Durach-Stiftung' },
  { value: 'edelweiss_pension', label: 'Edelweiss Vorsorgestiftung' },
  { value: 'alcan_supplementary_fund', label: 'Ergänzungskasse Alcan Schweiz' },
  { value: 'etia_pension', label: 'ETIA-Stiftung' },
  { value: 'fina_pension', label: 'Fina Vorsorgestiftung in Liquidation' },
  {
    value: 'swiss_life_finance_pension',
    label: 'Finanzierungsstiftung berufliche Vorsorge Swiss Life',
  },
  {
    value: 'axa_finance_pension',
    label: 'Finanzierungsstiftung der AXA Versicherungs-Gesellschaften',
  },
  {
    value: 'scor_swiss_finance_pension',
    label: 'Finanzierungsstiftung der SCOR Schweiz',
  },
  {
    value: 'lindt_finance_pension',
    label:
      'Finanzierungsstiftung für die Vorsorgeeinrichtungen der Chocoladefabriken Lindt & Sprüngli AG',
  },
  {
    value: 'unilever_finance_pension',
    label: 'Finanzierungsstiftung Unilever Schweiz GmbH',
  },
  { value: 'okey_finance_pension', label: 'Finanzierungstiftung der Okey AG' },
  { value: 'fisca_ubs_pension', label: 'Fisca Vorsorgestiftung der UBS AG' },
  {
    value: 'eps_finance_pension',
    label:
      "Fondation de prévoyance du personnel d'EPS Finance SA in Liquidation",
  },
  {
    value: 'lindt_supplementary_fund',
    label:
      'Fonds für Pensionsergänzungen der Chocoladefabriken Lindt & Sprüngli AG',
  },
  {
    value: 'franz_personal_pension',
    label: 'Franz AG Personalvorsorgestiftung',
  },
  {
    value: 'migros_bank_freizügigkeitsstiftung',
    label: 'Freizügigkeitsstiftung der Migros Bank',
  },
  {
    value: 'zkb_freizügigkeitsstiftung',
    label: 'Freizügigkeitsstiftung der Zürcher Kantonalbank',
  },
  {
    value: 'swiss_life_freizügigkeitsstiftung',
    label: 'Freizügigkeitsstiftung Swiss Life',
  },
  { value: 'baur_welfare_fund', label: 'Fürsorgefonds Baur & Cie AG' },
  {
    value: 'minimax_welfare_fund',
    label: 'Fürsorgefonds der Firma Minimax AG',
  },
  { value: 'gessner_welfare_fund', label: 'Fürsorgefonds der Gessner Gruppe' },
  {
    value: 'sanitas_troesch_welfare_fund',
    label: 'Fürsorgefonds der Sanitas Troesch AG',
  },
  {
    value: 'stahl_contor_welfare_fund',
    label: 'Fürsorgefonds der Stahl-Contor Aktiengesellschaft',
  },
  {
    value: 'tonwerke_thayngen_welfare_fund',
    label: 'Fürsorgefonds der Tonwerke Thayngen AG',
  },
  {
    value: 'ernst_geiger_bauknecht_welfare_fund',
    label: 'Fürsorgefonds Ernst & Co, Inh. Geiger & Bauknecht',
  },
  { value: 'conzzeta_welfare_fund', label: 'Fürsorgestiftung Conzzeta' },
  {
    value: 'credit_suisse_welfare_fund',
    label: 'Fürsorgestiftung der Credit Suisse Group (Schweiz)',
  },
  { value: 'elektron_welfare_fund', label: 'Fürsorgestiftung der Elektron AG' },
  { value: 'espazium_welfare_fund', label: 'Fürsorgestiftung der espazium AG' },
  {
    value: 'amsler_welfare_fund',
    label: 'Fürsorge-Stiftung der Firma Amsler & Co AG, Feuerthalen',
  },
  {
    value: 'angst_pfister_welfare_fund',
    label:
      'Fürsorgestiftung der Firma Angst + Pfister AG, Zürich, und affiliierter Gesellschaften',
  },
  {
    value: 'gessner_group_welfare_fund',
    label: 'Fürsorgestiftung der Gessner Gruppe',
  },
  {
    value: 'hans_kohler_welfare_fund',
    label: 'Fürsorgestiftung der Hans Kohler AG',
  },
  {
    value: 'leemann_bretscher_welfare_fund',
    label: 'Fürsorgestiftung der Leemann + Bretscher AG, Bauunternehmung',
  },
  {
    value: 'maerz_ofenbau_welfare_fund',
    label: 'Fürsorgestiftung der Maerz-Ofenbau AG, Zürich',
  },
  {
    value: 'schweizerische_missionsgemeinschaft_welfare_fund',
    label: 'Fürsorgestiftung der Schweizerischen Missions-Gemeinschaft',
  },
  { value: 'testex_welfare_fund', label: 'Fürsorgestiftung der Testex AG' },
  {
    value: 'diethelm_keller_staff_welfare',
    label: 'Fürsorgestiftung des Personals der Diethelm Keller Holding AG',
  },
  {
    value: 'klinik_hirslanden_staff_welfare',
    label: 'Fürsorgestiftung des Personals der Klinik Hirslanden',
  },
  {
    value: 'wagerenhof_staff_welfare',
    label: 'Fürsorgestiftung des Wagerenhofes Uster',
  },
  {
    value: 'economiesuisse_welfare_fund',
    label: 'Fürsorgestiftung economiesuisse',
  },
  {
    value: 'beyer_chronometrie_staff_welfare',
    label: 'Fürsorgestiftung für das Personal der Beyer Chronometrie AG',
  },
  {
    value: 'sbv_welfare_fund',
    label: 'Fürsorgestiftung II des Schweizerischen Baumeisterverbandes',
  },
  { value: 'rex_royal_welfare_fund', label: 'Fürsorgestiftung Rex-Royal AG' },
  {
    value: 'jaeckli_geologie_staff_welfare',
    label:
      'Fürsorgestiftung zugunsten des Personals der Firma Jäckli Geologie AG',
  },
  { value: 'gdz_rent_pension', label: 'gdz-Rentenkasse' },
  {
    value: 'zellweger_luwa_community_fund',
    label: 'Gemeinschaftsstiftung der Zellweger Luwa AG',
  },
  { value: 'giesshübel_pension', label: 'Giesshübel-Stiftung in Liquidation' },
  { value: 'hans_wagner_pension', label: 'Hans Wagner Stiftung' },
  { value: 'hartwag_personal_pension', label: 'Hartwag-Personalstiftung' },
  { value: 'heinrich_kuhn_pension', label: 'Heinrich Kuhn-Stiftung' },
  {
    value: 'heinrich_margrit_landert_pension',
    label: 'Heinrich und Margrit Landert-Pfister-Stiftung',
  },
  {
    value: 'heinz_baer_pension',
    label: 'Heinz Bär-Stiftung der Firma Elektro Bär',
  },
  { value: 'heks_personal_pension', label: 'HEKS-Personalfürsorgestiftung' },
  {
    value: 'hermann_spruengli_pension',
    label: 'Hermann Sprüngli Personalstiftung',
  },
  {
    value: 'hess_honegger_pension',
    label: 'Hess-Honegger Personalvorsorgestiftung für die Embru-Werke',
  },
  { value: 'ihag_personal_pension', label: 'IHAG Personalvorsorgestiftung' },
  {
    value: 'integra_personal_pension',
    label: 'Integra Personalvorsorgestiftung',
  },
  {
    value: 'intercity_personal_welfare',
    label: 'Intercity Personalfürsorgestiftung',
  },
  {
    value: 'invor_industry_pension',
    label: 'inVor Vorsorgeeinrichtung Industrie',
  },
  { value: 'ituma_personal_pension', label: 'Ituma Personalvorsorgestiftung' },
  { value: 'iwaz_fund', label: 'IWAZ-Fonds' },
  {
    value: 'j_u_keller_general_welfare',
    label: 'J. U. Keller - Allgemeiner Wohlfahrtsfonds',
  },
  { value: 'jet_aviation_pension', label: 'Jet Aviation Vorsorgestiftung' },
  { value: 'johann_georg_pension', label: 'Johann Georg-Stiftung' },
  {
    value: 'johann_jakob_sulzer_pension',
    label: 'Johann Jakob Sulzer Stiftung',
  },
  {
    value: 'siemens_executive_pension',
    label: 'Kaderstiftung der Siemens-Gesellschaften in der Schweiz',
  },
  {
    value: 'lenz_staehelin_executive_pension',
    label: 'Kaderstiftung Lenz & Staehelin, Zürich',
  },
  {
    value: 'sairgroup_executive_insurance',
    label: 'Kaderversicherung der SAirGroup',
  },
  { value: 'kibag_personal_pension', label: 'KIBAG Personalstiftung' },
  {
    value: 'zurich_insurance_group_pension',
    label: 'Konzern-Vorsorgestiftung der Zürich Versicherungs-Gruppe',
  },
  { value: 'kpmg_personal_pension', label: 'KPMG Personalvorsorgestiftung' },
  {
    value: 'teachers_insurance_fund_zurich',
    label: 'Lehrerversicherungskasse der Freien Evangelischen Schule Zürich 1',
  },
  {
    value: 'zkb_marienburg_pension',
    label: 'Marienburg-Stiftung der Zürcher Kantonalbank',
  },
  { value: 'marsh_mclennan_pension', label: 'Marsh & McLennan Pension Fund' },
  { value: 'merbag_personal_pension', label: 'Merbag-Personalstiftung' },
  { value: 'mettler_toledo_welfare', label: 'Mettler-Toledo Fürsorgestiftung' },
  { value: 'mettler_toledo_pension', label: 'Mettler-Toledo Pensionskasse' },
  { value: 'migros_pension', label: 'Migros-Pensionskasse' },
  {
    value: 'nanny_huber_werdmueller_pension',
    label: 'Nanny Huber-Werdmüller-Stiftung',
  },
  { value: 'nest_pension', label: 'NEST Sammelstiftung' },
  { value: 'orell_fuessli_pension', label: 'Orell Füssli-Stiftung' },
  {
    value: 'ebp_partners_pension',
    label: 'Partnervorsorgefonds EBP Schweiz AG',
  },
  {
    value: 'haeusermann_company_welfare',
    label:
      'Patronale Fürsorgestiftung der Firma Fritz Häusermann Automobile AG, Zürich',
  },
  {
    value: 'bosshard_transport_welfare',
    label:
      'Patronale Personalfürsorgestiftung der Firma Bosshard Transport AG Bauma',
  },
  {
    value: 'schellenberg_printing_welfare',
    label: 'Patronale Personalfürsorgestiftung der Schellenberg Druck AG',
  },
  {
    value: 'spross_holding_welfare',
    label: 'Patronale Personalfürsorgestiftung der Spross-Holding AG',
  },
  {
    value: 'trafag_company_welfare',
    label: 'Patronale Personalstiftung der Trafag AG in Liquidation',
  },
  {
    value: 'burkhalter_holding_welfare',
    label: 'Patronale Stiftung der Burkhalter Holding AG',
  },
  {
    value: 'geistlich_wohler_pension',
    label: 'Patronale Stiftung der Ed. Geistlich Söhne AG',
  },
  {
    value: 'hg_commercial_pension',
    label: 'Patronale Stiftung der HG COMMERCIALE',
  },
  {
    value: 'weisbrod_zuerrer_pension',
    label: 'Patronale Stiftung der Weisbrod-Zürrer AG',
  },
  {
    value: 'svti_inspection_pension',
    label:
      'Patronale Stiftung des SVTI Schweizerischer Verein für technische Inspektionen',
  },
  {
    value: 'kaelin_mueller_pension',
    label: 'Patronale Stiftung Kälin & Müller AG',
  },
  {
    value: 'schenker_switzerland_pension',
    label: 'Patronale Vorsorgestiftung der Schenker Schweiz AG',
  },
  {
    value: 'big_kaiser_precision_welfare',
    label: 'Patronale Wohlfahrtsstiftung der Big Kaiser Präzisionswerkzeuge AG',
  },
  {
    value: 'zurich_insurance_funding_fund',
    label:
      'Patronaler Finanzierungsfonds der Zürich Versicherungs-Gesellschaft AG',
  },
  {
    value: 'henauer_gugler_welfare',
    label: 'Patronaler Wohlfahrtsfonds der Henauer Gugler AG',
  },
  {
    value: 'meier_tobler_welfare',
    label: 'Patronaler Wohlfahrtsfonds der Meier Tobler Group AG',
  },
  {
    value: 'willy_group_welfare',
    label: 'Patronaler Wohlfahrtsfonds der Th. Willy-Gruppe',
  },
  {
    value: 'eskimo_textile_welfare',
    label: 'Patronaler-Fonds der Eskimo Textil AG',
  },
  {
    value: 'raeber_education_welfare',
    label: 'Patronal-Fürsorgestiftung raeber education ag',
  },
  {
    value: 'gastro_suisse_pension',
    label: 'Pensionsfonds Gruppe GastroSuisse',
  },
  {
    value: 'credit_suisse_2_pension',
    label: 'Pensionskasse 2 der Credit Suisse Group (Schweiz)',
  },
  { value: 'alcan_pension', label: 'Pensionskasse Alcan Schweiz' },
  { value: 'allreal_pension', label: 'Pensionskasse Allreal' },
  { value: 'alstom_pension', label: 'Pensionskasse ALSTOM Schweiz AG' },
  { value: 'apg_sga_pension', label: 'Pensionskasse APG/SGA' },
  { value: 'baltensperger_pension', label: 'Pensionskasse Baltensperger AG' },
  {
    value: 'boa_merrill_lynch_pension',
    label: 'Pensionskasse Bank of America Merrill Lynch',
  },
  { value: 'baxter_pension', label: 'Pensionskasse Baxter' },
  {
    value: 'blue_cross_switzerland_pension',
    label: 'Pensionskasse Blaues Kreuz Schweiz',
  },
  { value: 'bonassistus_pension', label: 'Pensionskasse BonAssistus' },
  { value: 'cembra_pension', label: 'Pensionskasse Cembra' },
  { value: 'conzzeta_pension', label: 'Pensionskasse Conzzeta' },
  { value: 'denner_pension', label: 'Pensionskasse Denner in Liquidation' },
  { value: '3m_pension', label: 'Pensionskasse der 3M Firmen in der Schweiz' },
  {
    value: 'alcatel_lucent_pension',
    label: 'Pensionskasse der Alcatel-Lucent Schweiz AG',
  },
  {
    value: 'bank_vontobel_pension',
    label: 'Pensionskasse der Bank Vontobel AG',
  },
  {
    value: 'baumann_koelliker_pension',
    label: 'Pensionskasse der Baumann Koelliker Gruppe',
  },
  {
    value: 'bouygues_services_pension',
    label: 'Pensionskasse der Bouygues Energies & Services Schweiz',
  },
  {
    value: 'falken_brewery_pension',
    label: 'Pensionskasse der Brauerei Falken AG Schaffhausen',
  },
  {
    value: 'burkhalter_group_pension',
    label: 'Pensionskasse der Burkhalter Gruppe',
  },
  {
    value: 'coutts_bank_pension',
    label: 'Pensionskasse der Coutts & Co AG in Liquidation',
  },
  {
    value: 'credit_suisse_pension',
    label: 'Pensionskasse der Credit Suisse Group (Schweiz)',
  },
  {
    value: 'dosenbach_ochsner_pension',
    label: 'Pensionskasse der Dosenbach-Ochsner AG Schuhe und Sport',
  },
  { value: 'electrolux_pension', label: 'Pensionskasse der Electrolux AG' },
  {
    value: 'electro_material_pension',
    label: 'Pensionskasse der Elektro-Material AG, Zürich',
  },
  { value: 'emil_frey_pension', label: 'Pensionskasse der Emil Frey Gruppe' },
  {
    value: 'ernst_schweizer_pension',
    label: 'Pensionskasse der Ernst Schweizer AG',
  },
  {
    value: 'angst_pfister_pension',
    label:
      'Pensionskasse der Firma Angst + Pfister AG, Zürich und affiliierter Gesellschaften',
  },
  {
    value: 'fisch_asset_management_pension',
    label: 'Pensionskasse der Fisch Asset Management AG',
  },
  {
    value: 'horgen_community_pension',
    label: 'Pensionskasse der Gemeinde Horgen',
  },
  {
    value: 'generali_insurance_pension',
    label: 'Pensionskasse der Generali Versicherungen',
  },
  {
    value: 'gipser_maler_zurich_pension',
    label:
      'Pensionskasse der Gipser- und Malergenossenschaft Zürich und Umgebung',
  },
  {
    value: 'guentensperger_group_pension',
    label: 'Pensionskasse der Güntensperger Gruppe',
  },
  { value: 'hg_commercial_pension', label: 'Pensionskasse der HG COMMERCIALE' },
  {
    value: 'honeywell_switzerland_pension',
    label: 'Pensionskasse der Honeywell Schweiz',
  },
  {
    value: 'hunziker_ag_pension',
    label: 'Pensionskasse der Hunziker AG Thalwil in Liquidation',
  },
  { value: 'iss_switzerland_pension', label: 'Pensionskasse der ISS Schweiz' },
  {
    value: 'landert_group_pension',
    label: 'Pensionskasse der Landert Group AG',
  },
  {
    value: 'lyreco_switzerland_pension',
    label: 'Pensionskasse der Lyreco Switzerland AG',
  },
  {
    value: 'marmoran_maxit_pension',
    label: 'Pensionskasse der Marmoran Maxit AG',
  },
  { value: 'nzz_pension', label: 'Pensionskasse der NZZ' },
  { value: 'orior_group_pension', label: 'Pensionskasse der Orior Gruppe' },
  {
    value: 'thalwil_community_pension',
    label: 'Pensionskasse der politischen Gemeinde Thalwil',
  },
  { value: 'pro_infirmis_pension', label: 'Pensionskasse der Pro Infirmis' },
  {
    value: 'retail_operating_company_pension',
    label: 'Pensionskasse der Retail Operating Company Schweiz GmbH',
  },
  { value: 'sada_pension', label: 'Pensionskasse der SADA AG' },
  {
    value: 'scania_switzerland_pension',
    label: 'Pensionskasse der Scania Schweiz AG',
  },
  {
    value: 'schlagenhauf_group_pension',
    label: 'Pensionskasse der Schlagenhauf Gruppe',
  },
  {
    value: 'schlatter_group_pension',
    label: 'Pensionskasse der Schlatter-Gruppe',
  },
  {
    value: 'schneider_electric_pension',
    label: 'Pensionskasse der Schneider Electric-Gesellschaften Schweiz',
  },
  {
    value: 'swiss_epilepsy_stiftung_pension',
    label: 'Pensionskasse der Schweizerischen Epilepsie-Stiftung, Zürich',
  },
  {
    value: 'swiss_hail_insurance_pension',
    label: 'Pensionskasse der Schweizerischen Hagel-Versicherungs-Gesellschaft',
  },
  {
    value: 'swiss_national_bank_pension',
    label: 'Pensionskasse der Schweizerischen Nationalbank (Genossenschaft)',
  },
  {
    value: 'siemens_switzerland_pension',
    label: 'Pensionskasse der Siemens-Gesellschaften in der Schweiz',
  },
  {
    value: 'skf_switzerland_pension',
    label: 'Pensionskasse der SKF (Schweiz)',
  },
  {
    value: 'sr_technics_pension',
    label: 'Pensionskasse der SR Technics Switzerland',
  },
  {
    value: 'adliswil_city_pension',
    label: 'Pensionskasse der Stadt Adliswil in Liquidation',
  },
  {
    value: 'duebendorf_city_pension',
    label: 'Pensionskasse der Stadt Dübendorf',
  },
  {
    value: 'winterthur_city_pension',
    label: 'Pensionskasse der Stadt Winterthur',
  },
  { value: 'steiner_ag_pension', label: 'Pensionskasse der Steiner AG' },
  { value: 'tx_group_pension', label: 'Pensionskasse der TX Group AG' },
  { value: 'ubs_pension', label: 'Pensionskasse der UBS' },
  {
    value: 'vebego_switzerland_pension',
    label: 'Pensionskasse der Vebego Schweiz',
  },
  {
    value: 'wr_weaving_russikon_pension',
    label: 'Pensionskasse der WR Weberei Russikon AG',
  },
  { value: 'zkb_pension', label: 'Pensionskasse der Zürcher Kantonalbank' },
  {
    value: 'zurich_insurance_group_pension',
    label: 'Pensionskasse der Zürich Versicherungs-Gruppe',
  },
  {
    value: 'diakoniewerk_neumuenster_pension',
    label: 'Pensionskasse des Diakoniewerkes Neumünster',
  },
  {
    value: 'zurich_opera_house_pension',
    label: 'Pensionskasse des Opernhauses Zürich',
  },
  {
    value: 'swiss_public_services_pension',
    label:
      'Pensionskasse des Schweizerischen Verbandes des Personals öffentlicher Dienste (V.P.O.D.)',
  },
  {
    value: 'svti_technical_inspection_pension',
    label:
      'Pensionskasse des SVTI, Schweizerischer Verein für technische Inspektionen',
  },
  { value: 'dksh_pension', label: 'Pensionskasse DKSH' },
  { value: 'dormakaba_pension', label: 'Pensionskasse dormakaba' },
  { value: 'dufry_pension', label: 'Pensionskasse Dufry' },
  { value: 'ebp_switzerland_pension', label: 'Pensionskasse EBP Schweiz AG' },
  { value: 'elco_pension', label: 'Pensionskasse ELCO' },
  {
    value: 'catholic_church_zurich_pension',
    label:
      'Pensionskasse für Angestellte der römisch-katholischen Kirchgemeinden des Kantons Zürich',
  },
  {
    value: 'axa_switzerland_pension',
    label: 'Pensionskasse für die AXA Schweiz',
  },
  {
    value: 'maennedorf_community_pension',
    label: 'Pensionskasse Gemeinde Männedorf',
  },
  { value: 'georg_fischer_pension', label: 'Pensionskasse Georg Fischer' },
  { value: 'hirslanden_pension', label: 'Pensionskasse Hirslanden' },
  { value: 'hug_baustoffe_pension', label: 'Pensionskasse Hug Baustoffe AG' },
  { value: 'imbrex_pension', label: 'Pensionskasse Imbrex in Liquidation' },
  {
    value: 'johnson_johnson_switzerland_pension',
    label: 'Pensionskasse Johnson & Johnson Schweiz',
  },
  { value: 'jumbo_pension', label: 'Pensionskasse Jumbo in Liquidation' },
  { value: 'kuessnacht_pension', label: 'Pensionskasse Küsnacht' },
  {
    value: 'lindt_spruengli_pension',
    label: 'Pensionskasse Lindt & Sprüngli AG',
  },
  {
    value: 'maag_pump_systems_pension',
    label: 'Pensionskasse maag-pump-systems',
  },
  { value: 'media_markt_pension', label: 'Pensionskasse Media Markt' },
  { value: 'meier_tobler_pension', label: 'Pensionskasse Meier Tobler' },
  { value: 'merck_pension', label: 'Pensionskasse Merck & Cie' },
  { value: 'merlion_pension', label: 'Pensionskasse Merlion' },
  {
    value: 'p_schweiz_employees_pension',
    label: 'Pensionskasse Mitarbeitende P-Schweiz',
  },
  {
    value: 'mondelez_switzerland_pension',
    label: 'Pensionskasse Mondelez Schweiz',
  },
  { value: 'pestalozzi_pension', label: 'Pensionskasse Pestalozzi' },
  {
    value: 'pig_coop_pension',
    label: 'Pensionskasse PIG, Genossenschaft in Liquidation',
  },
  { value: 'rex_royal_pension', label: 'Pensionskasse Rex-Royal AG' },
  { value: 'rheinmetall_pension', label: 'Pensionskasse Rheinmetall' },
  { value: 'saviva_pension', label: 'Pensionskasse Saviva AG in Liquidation' },
  { value: 'schaffhausen_pension', label: 'Pensionskasse Schaffhausen' },
  {
    value: 'swiss_re_pension',
    label:
      'Pensionskasse Schweizerische Rückversicherungs-Gesellschaft (Swiss Re)',
  },
  { value: 'shp_pension', label: 'Pensionskasse SHP' },
  {
    value: 'socar_energy_switzerland_pension',
    label: 'Pensionskasse SOCAR Energy Switzerland',
  },
  { value: 'sps_jelmoli_pension', label: 'Pensionskasse SPS und Jelmoli' },
  { value: 'zurich_city_pension', label: 'Pensionskasse Stadt Zürich' },
  { value: 'syna_pension', label: 'Pensionskasse SYNA' },
  { value: 'tius_pension', label: 'Pensionskasse TIUS in Liquidation' },
  {
    value: 'unilever_switzerland_pension',
    label: 'Pensionskasse Unilever Schweiz',
  },
  { value: 'vetropack_pension', label: 'Pensionskasse Vetropack' },
  { value: 'walo_pension', label: 'Pensionskasse WALO' },
  {
    value: 'wunderman_thompson_pension',
    label: 'Pensionskasse Wunderman Thompson',
  },
  { value: 'zueriwerk_pension', label: 'Pensionskasse Züriwerk' },
  {
    value: 'blood_donation_zurich_pension',
    label: 'Pensionsstiftung der Stiftung Zürcher Blutspendedienst SRK',
  },
  {
    value: 'trafag_company_welfare',
    label: 'Personal- und Fürsorgefonds der Trafag AG',
  },
  { value: 'ew_wald_company_welfare', label: 'Personalfonds der EW Wald AG' },
  {
    value: 'zurich_airport_company_welfare',
    label: 'Personalfonds der Flughafen Zürich AG',
  },
  {
    value: 'benz_company_welfare',
    label: 'Personal-Fürsorgeeinrichtung der Benz + Cie AG',
  },
  {
    value: 'bibus_holding_company_welfare',
    label: 'Personalfürsorgefonds der Bibus Holding AG',
  },
  {
    value: 'aeschbach_company_welfare',
    label: 'Personalfürsorgefonds der F. Aeschbach AG',
  },
  {
    value: 'binelli_ehrsam_company_welfare',
    label:
      'Personal-Fürsorge-Fonds der Firma Binelli & Ehrsam Aktiengesellschaft in Liquidation',
  },
  {
    value: 'wehle_co_company_welfare',
    label: 'Personalfürsorgefonds der Firma Wehle + Co',
  },
  {
    value: 'stuecheli_architects_company_welfare',
    label: 'Personalfürsorge-Fonds der Stücheli Architekten AG',
  },
  {
    value: 'abogado_company_welfare',
    label: 'Personalfürsorgestiftung Abogado',
  },
  {
    value: 'bachmann_binding_company_welfare',
    label: 'Personalfürsorgestiftung Bachmann AG Grossbuchbinderei',
  },
  {
    value: 'accurata_company_welfare',
    label: 'Personalfürsorgestiftung der Accurata Holding AG',
  },
  {
    value: 'anson_company_welfare',
    label: 'Personalfürsorgestiftung der Anson AG Zürich',
  },
  {
    value: 'bank_vontobel_company_welfare',
    label: 'Personalfürsorgestiftung der Bank Vontobel AG',
  },
  {
    value: 'erzinger_company_welfare',
    label: 'Personalfürsorgestiftung der Bürstenfabrik Erzinger AG, Wädenswil',
  },
  {
    value: 'chromos_group_company_welfare',
    label: 'Personalfürsorgestiftung der CHROMOS Group AG',
  },
  {
    value: 'confiserie_teuscher_company_welfare',
    label: 'Personalfürsorgestiftung der confiserie teuscher AG',
  },
  {
    value: 'electro_material_company_welfare',
    label: 'Personalfürsorge-Stiftung der Elektro-Material AG Zürich',
  },
  {
    value: 'hausammann_company_welfare',
    label: 'Personalfürsorgestiftung der Ernst Hausammann & Co. AG, Zürich',
  },
  {
    value: 'ernst_meier_company_welfare',
    label: 'Personalfürsorgestiftung der Ernst Meier Aktiengesellschaft',
  },
  {
    value: 'ernst_schuetz_company_welfare',
    label: 'Personalfürsorgestiftung der Ernst Schütz Kies + Beton AG',
  },
  {
    value: 'alfred_wertli_company_welfare',
    label: 'Personalfürsorgestiftung der Firma Alfred Wertli AG',
  },
  {
    value: 'arthur_widmer_company_welfare',
    label: 'Personalfürsorgestiftung der Firma Arthur Widmer AG',
  },
  {
    value: 'bertschi_bakery_company_welfare',
    label:
      'Personalfürsorgestiftung der Firma Bertschi Bäckerei zum Brotkorb AG',
  },
  {
    value: 'impag_company_welfare',
    label:
      'Personalfürsorgestiftung der Firma Chemie-Import AG Schweiz. Industrieller, "Impag", Zürich in Liquidation',
  },
  {
    value: 'bollinger_furniere_company_welfare',
    label:
      'Personalfürsorgestiftung der Firma E. Bollinger Furniere AG, Nürensdorf/ZH',
  },
  {
    value: 'eduard_steiner_company_welfare',
    label: 'Personalfürsorgestiftung der Firma Eduard Steiner AG Rikon',
  },
  {
    value: 'eio_paint_company_welfare',
    label: 'Personalfürsorge-Stiftung der Firma EIO Lack- und Farbenfabrik AG',
  },
  {
    value: 'bianchi_company_welfare',
    label: 'Personalfürsorgestiftung der Firma G. Bianchi AG',
  },
  {
    value: 'geb_maag_company_welfare',
    label:
      'Personal-Fürsorgestiftung der Firma Gebr. Maag, Maschinenfabrik AG, in Küsnacht',
  },
  {
    value: 'kracht_erben_company_welfare',
    label: "Personalfürsorgestiftung der Firma H. Kracht's Erben AG, Zürich",
  },
  {
    value: 'hauenstein_company_welfare',
    label: 'Personalfürsorgestiftung der Firma Hauenstein AG, Rafz',
  },
  {
    value: 'heinz_company_welfare',
    label: 'Personalfürsorgestiftung der Firma Heinz & Co. AG',
  },
  {
    value: 'jaeggi_hafter_company_welfare',
    label:
      'Personalfürsorgestiftung der Firma Jäggi & Hafter Aktiengesellschaft, Zürich',
  },
  {
    value: 'kläui_company_welfare',
    label: 'Personalfürsorgestiftung der Firma Kläui AG',
  },
  {
    value: 'landolt_company_welfare',
    label:
      'Personalfürsorgestiftung der Firma LANDOLT + CO AG Bauunternehmung, Kleinandelfingen',
  },
  {
    value: 'lenzlinger_company_welfare',
    label: 'Personalfürsorgestiftung der Firma Lenzlinger Söhne AG',
  },
  {
    value: 'louis_widmer_company_welfare',
    label: 'Personalfürsorgestiftung der Firma Louis Widmer AG',
  },
  {
    value: 'maegerle_machines_company_welfare',
    label: 'Personalfürsorgestiftung der Firma Mägerle AG Maschinenfabrik',
  },
  {
    value: 'angst_meat_company_welfare',
    label: 'Personalfürsorge-Stiftung der Firma Metzgerei Angst AG',
  },
  {
    value: 'oswald_boll_company_welfare',
    label:
      'Personalfürsorgestiftung der Firma Oswald Boll AG, Zollikon in Liquidation',
  },
  {
    value: 'robert_schaub_company_welfare',
    label: 'Personalfürsorgestiftung der Firma Robert Schaub AG in Liquidation',
  },
  {
    value: 'selectchemie_company_welfare',
    label: 'Personalfürsorgestiftung der Firma Selectchemie AG',
  },
  {
    value: 'sinco_treuhand_company_welfare',
    label: 'Personalfürsorgestiftung der Firma Sinco Treuhand AG',
  },
  {
    value: 'xerox_company_welfare',
    label: 'Personalfürsorgestiftung der Firma Xerox AG',
  },
  {
    value: 'zurich_warehouse_company_welfare',
    label: 'Personalfürsorgestiftung der Firma Zürcher Lagerhaus AG',
  },
  {
    value: 'fujifilm_switzerland_company_welfare',
    label:
      'Personalfürsorgestiftung der Fujifilm (Switzerland) AG in Liquidation',
  },
  {
    value: 'gamma_company_welfare',
    label: 'Personalfürsorgestiftung der Gamma AG',
  },
  {
    value: 'general_motors_switzerland_company_welfare',
    label:
      'Personalfürsorgestiftung der General Motors Gesellschaften der Schweiz in Liquidation',
  },
  {
    value: 'gerber_instruments_company_welfare',
    label: 'Personalfürsorgestiftung der Gerber Instruments AG',
  },
  {
    value: 'goodyear_switzerland_company_welfare',
    label: 'Personalfürsorge-Stiftung der Goodyear Suisse SA, Volketswil',
  },
  {
    value: 'german_swiss_chamber_of_commerce_company_welfare',
    label: 'Personalfürsorgestiftung der Handelskammer Deutschland-Schweiz',
  },
  {
    value: 'helbling_group_company_welfare',
    label: 'Personalfürsorgestiftung der Helbling-Gruppe',
  },
  {
    value: 'jegen_company_welfare',
    label: 'Personalfürsorgestiftung der Jegen AG',
  },
  {
    value: 'licht_kraftwerke_glattfelden_company_welfare',
    label: 'Personalfürsorgestiftung der Licht- und Kraftwerke Glattfelden',
  },
  {
    value: 'meteolabor_company_welfare',
    label: 'Personalfürsorgestiftung der Meteolabor AG',
  },
  {
    value: 'naef_flugmechanik_company_welfare',
    label: 'Personalfürsorgestiftung der Naef Flugmechanik AG',
  },
  {
    value: 'pfenninger_company_welfare',
    label: 'Personalfürsorgestiftung der Pfenninger Unternehmungen AG',
  },
  {
    value: 'sahli_company_welfare',
    label: 'Personalfürsorge-Stiftung der Sahli AG',
  },
  {
    value: 'waidmatt_settlement_building_association_company_welfare',
    label:
      'Personalfürsorgestiftung der Siedlungs- und Baugenossenschaft Waidmatt',
  },
  {
    value: 'wanger_ulrich_stamp_factory_company_welfare',
    label: 'Personalfürsorgestiftung der Stempelfabrik Wanger + Ulrich AG',
  },
  {
    value: 'surber_metal_construction_company_welfare',
    label: 'Personalfürsorgestiftung der Surber-Metallbau AG',
  },
  {
    value: 'emilienheim_kilchberg_company_welfare',
    label: 'Personalfürsorgestiftung des Emilienheimes Kilchberg',
  },
  { value: 'dksh_company_welfare', label: 'Personalfürsorgestiftung DKSH' },
  {
    value: 'haeusermann_company_welfare',
    label: 'Personalfürsorgestiftung Fritz Häusermann in Liquidation',
  },
  {
    value: 'maerki_baumann_company_welfare',
    label:
      'Personalfürsorgestiftung für die Angestellten der Firma Maerki, Baumann & Co. AG Zürich',
  },
  {
    value: 'hemmi_coffee_company_welfare',
    label: 'Personalfürsorgestiftung Hemmi Kaffee AG in Liquidation',
  },
  {
    value: 'jaermann_co_company_welfare',
    label: 'Personalfürsorgestiftung Järmann & Co. AG',
  },
  {
    value: 'robert_ober_company_welfare',
    label: 'Personal-Fürsorge-Stiftung Robert Ober AG',
  },
  {
    value: 'robert_spleiss_company_welfare',
    label: 'Personalfürsorgestiftung Robert Spleiss AG',
  },
  {
    value: 'awag_electrical_engineering_company_welfare',
    label: 'Personalstiftung Awag Elektrotechnik AG',
  },
  { value: 'crb_company_welfare', label: 'Personalstiftung CRB' },
  {
    value: 'armin_hunziker_company_welfare',
    label: 'Personalstiftung der Armin Hunziker AG in Liquidation',
  },
  {
    value: 'bachofen_company_welfare',
    label: 'Personalstiftung der Bachofen AG',
  },
  {
    value: 'bruetsch_rueegger_holding_company_welfare',
    label: 'Personalstiftung der Brütsch/Rüegger Holding AG',
  },
  {
    value: 'burri_group_company_welfare',
    label: 'Personalstiftung der Burri-Gruppe',
  },
  {
    value: 'pluess_company_welfare',
    label: 'Personalstiftung der Firma C. Plüss + Co AG',
  },
  {
    value: 'hammer_cooperative_company_welfare',
    label: 'Personalstiftung der Genossenschaft Hammer',
  },
  {
    value: 'lateltin_company_welfare',
    label: 'Personalstiftung der Lateltin AG',
  },
  {
    value: 'leather_locher_company_welfare',
    label: 'Personal-Stiftung der Leder Locher AG',
  },
  {
    value: 'oertli_tools_company_welfare',
    label: 'Personalstiftung der Oertli Werkzeuge AG',
  },
  {
    value: 'rené_faigle_company_welfare',
    label: 'Personalstiftung der René Faigle AG',
  },
  {
    value: 'rothschild_bank_company_welfare',
    label: 'Personalstiftung der Rothschild & Co Bank AG',
  },
  {
    value: 'sada_company_welfare',
    label: 'Personalstiftung der SADA AG in Liquidation',
  },
  {
    value: 'schmidlin_company_welfare',
    label: 'Personalstiftung der Schmidlin AG',
  },
  {
    value: 'swiss_air_rescue_company_welfare',
    label: 'Personalstiftung der Schweizerischen Rettungsflugwacht',
  },
  {
    value: 'smc_group_company_welfare',
    label: 'Personalstiftung der SMC-Gruppe in Liquidation',
  },
  {
    value: 'buehl_foundation_company_welfare',
    label: 'Personalstiftung der Stiftung Bühl, Wädenswil',
  },
  {
    value: 'stoeri_company_welfare',
    label: 'Personalstiftung der Störi & Co. AG',
  },
  {
    value: 'trueb_company_welfare',
    label: 'Personalstiftung der Trüb AG Horgen',
  },
  {
    value: 'iwc_schaffhausen_company_welfare',
    label:
      'Personalstiftung der Zweigniederlassung IWC SCHAFFHAUSEN, Branch of Richemont International SA',
  },
  {
    value: 'karl_enzler_company_welfare',
    label: 'Personalstiftung Karl Enzler, Zürich',
  },
  {
    value: 'bank_zimmerberg_company_welfare',
    label: 'Personalversicherung der BANK ZIMMERBERG AG',
  },
  {
    value: 'ncr_switzerland_company_welfare',
    label: 'Personalversicherung der NCR (Schweiz)',
  },
  {
    value: 'pawi_packaging_company_welfare',
    label: 'Personalvorsorge der PAWI Verpackungen',
  },
  {
    value: 'gate_gourmet_switzerland_company_welfare',
    label: 'Personalvorsorge Gate Gourmet Switzerland',
  },
  { value: 'priora_company_welfare', label: 'Personalvorsorge Priora' },
  { value: 'swissport_company_welfare', label: 'Personalvorsorge Swissport' },
  {
    value: 'glockenhaus_foundation_company_welfare',
    label: 'Personalvorsorgeeinrichtung der Stiftung zum Glockenhaus',
  },
  { value: 'ford_company_welfare', label: 'Personal-Vorsorgeeinrichtung Ford' },
  {
    value: 'preisig_zurich_company_welfare',
    label: 'Personalvorsorgestiftung (BVG) der Preisig AG Zürich',
  },
  {
    value: 'bethanien_company_welfare',
    label: 'Personalvorsorgestiftung Bethanien',
  },
  {
    value: 'wmf_switzerland_company_welfare',
    label: 'Personalvorsorgestiftung BVG der WMF Schweiz AG in Liquidation',
  },
  {
    value: 'canon_switzerland_company_welfare',
    label: 'Personalvorsorgestiftung Canon (Schweiz) AG',
  },
  {
    value: 'accenture_switzerland_company_welfare',
    label: 'Personalvorsorgestiftung der Accenture Schweiz',
  },
  {
    value: 'albers_group_company_welfare',
    label: 'Personalvorsorgestiftung der Albers Gruppe',
  },
  {
    value: 'bank_sal_oppenheim_company_welfare',
    label:
      'Personalvorsorgestiftung der Bank Sal. Oppenheim jr. & Cie. (Schweiz) AG in Liquidation',
  },
  {
    value: 'basler_hofmann_company_welfare',
    label:
      'Personalvorsorgestiftung der Basler & Hofmann AG, Ingenieure und Planer',
  },
  {
    value: 'milk_hump_construction_association_company_welfare',
    label: 'Personalvorsorgestiftung der Baugenossenschaft Milchbuck',
  },
  {
    value: 'bearingpoint_company_welfare',
    label: 'Personalvorsorgestiftung der BearingPoint AG',
  },
  {
    value: 'belimo_automation_company_welfare',
    label: 'Personalvorsorgestiftung der BELIMO Automation AG',
  },
  {
    value: 'bellevue_group_company_welfare',
    label: 'Personalvorsorgestiftung der Bellevue Group',
  },
  {
    value: 'biomed_company_welfare',
    label: 'Personalvorsorgestiftung der Biomed AG',
  },
  {
    value: 'bmw_switzerland_company_welfare',
    label: 'Personalvorsorgestiftung der BMW (Schweiz) AG',
  },
  {
    value: 'bouygues_tec_group_company_welfare',
    label: 'Personalvorsorgestiftung der Bouygues E&S InTec-Gruppe',
  },
  {
    value: 'cargologic_company_welfare',
    label: 'Personalvorsorgestiftung der Cargologic AG',
  },
  {
    value: 'coca_cola_hbc_switzerland_company_welfare',
    label: 'Personalvorsorgestiftung der Coca-Cola HBC Schweiz AG',
  },
  {
    value: 'dxc_technology_switzerland_company_welfare',
    label: 'Personalvorsorgestiftung der DXC Technology Switzerland GmbH',
  },
  {
    value: 'eberhard_kluoten_company_welfare',
    label: 'Personalvorsorgestiftung der Eberhard Unternehmungen Kloten',
  },
  {
    value: 'geistlich_company_welfare',
    label: 'Personalvorsorgestiftung der Ed. Geistlich Söhne AG',
  },
  {
    value: 'ed_kuebler_company_welfare',
    label: 'Personalvorsorgestiftung der Ed. Kübler & Co. AG',
  },
  {
    value: 'electrowatt_engineering_company_welfare',
    label: 'Personalvorsorgestiftung der Electrowatt Engineering',
  },
  {
    value: 'electro_baer_company_welfare',
    label: 'Personalvorsorgestiftung der Elektro Bär Unternehmungen',
  },
  {
    value: 'electro_frauchiger_company_welfare',
    label:
      'Personalvorsorgestiftung der Elektro Frauchiger AG, Gossau in Liquidation',
  },
  {
    value: 'elvetino_company_welfare',
    label: 'Personalvorsorgestiftung der Elvetino AG',
  },
  {
    value: 'amstein_walthert_company_welfare',
    label: 'Personalvorsorgestiftung der Firma Amstein & Walthert AG',
  },
  {
    value: 'bertani_construction_company_welfare',
    label: 'Personalvorsorgestiftung der Firma Bertani Baugerüste AG',
  },
  {
    value: 'keller_pressure_measuring_technology_company_welfare',
    label: 'Personalvorsorgestiftung der Firma Keller Druckmesstechnik AG',
  },
  {
    value: 'max_mueller_welding_technology_company_welfare',
    label:
      'Personalvorsorgestiftung der Firma Max Müller Schweisstechnik, Inhaber: Jörg M. Müller',
  },
  {
    value: 'modissa_company_welfare',
    label: 'Personalvorsorgestiftung der Firma Modissa AG',
  },
  {
    value: 'pfiffner_company_welfare',
    label: 'Personalvorsorgestiftung der Firma Pfiffner AG',
  },
  {
    value: 'quendoz_glass_company_welfare',
    label: 'Personalvorsorgestiftung der Firma Quendoz Glas AG in Liquidation',
  },
  {
    value: 'saco_machine_building_company_welfare',
    label: 'Personalvorsorgestiftung der Firma SACO Maschinenbau AG',
  },
  {
    value: 'satronic_company_welfare',
    label: 'Personalvorsorgestiftung der Firma Satronic AG in Liquidation',
  },
  {
    value: 'schmid_telecom_company_welfare',
    label:
      'Personalvorsorgestiftung der Firma Schmid Telecom AG in Liquidation',
  },
  {
    value: 'g_bopp_company_welfare',
    label: 'Personalvorsorgestiftung der G. Bopp & Co. AG',
  },
  {
    value: 'zollikon_community_company_welfare',
    label: 'Personalvorsorgestiftung der Gemeinde Zollikon',
  },
  {
    value: 'gericke_company_welfare',
    label: 'Personalvorsorgestiftung der Gericke AG',
  },
  {
    value: 'gherzi_company_welfare',
    label: 'Personalvorsorgestiftung der Gherzi AG',
  },
  {
    value: 'gyso_company_welfare',
    label: 'Personalvorsorgestiftung der Gyso AG',
  },
  {
    value: 'helsana_insurance_company_welfare',
    label: 'Personalvorsorgestiftung der Helsana Versicherungen AG',
  },
  {
    value: 'hinderer_group_company_welfare',
    label: 'Personalvorsorgestiftung der Hinderer Gruppe in Liquidation',
  },
  {
    value: 'hogg_robinson_switzerland_company_welfare',
    label:
      'Personalvorsorgestiftung der Hogg Robinson Switzerland Ltd. in Liquidation',
  },
  {
    value: 'hoval_company_welfare',
    label: 'Personalvorsorgestiftung der Hoval AG',
  },
  {
    value: 'ibm_switzerland_company_welfare',
    label: 'Personalvorsorgestiftung der IBM (Schweiz)',
  },
  {
    value: 'kalaidos_education_group_company_welfare',
    label:
      'Personalvorsorgestiftung der Kalaidos Bildungsgruppe Schweiz, Zürich',
  },
  {
    value: 'keller_frei_company_welfare',
    label: 'Personalvorsorgestiftung der Keller-Frei AG',
  },
  {
    value: 'mercedes_benz_automobile_company_welfare',
    label: 'Personalvorsorgestiftung der Mercedes-Benz Automobil AG',
  },
  {
    value: 'christian_dior_company_welfare',
    label:
      'Personalvorsorgestiftung der Parfums Christian Dior AG und PC Parfums Cosmetiques SA',
  },
  {
    value: 'pfizer_company_welfare',
    label: 'Personalvorsorgestiftung der Pfizer AG in Liquidation',
  },
  {
    value: 'planzer_transport_company_welfare',
    label: 'Personalvorsorgestiftung der Planzer Transport AG',
  },
  {
    value: 'pletscher_company_welfare',
    label: 'Personalvorsorgestiftung der Pletscher AG',
  },
  {
    value: 'reichle_de_massari_company_welfare',
    label: 'Personalvorsorgestiftung der Reichle & De-Massari AG',
  },
  {
    value: 'schulthess_media_company_welfare',
    label: 'Personalvorsorgestiftung der Schulthess Juristische Medien AG',
  },
  {
    value: 'scor_switzerland_company_welfare',
    label: 'Personalvorsorgestiftung der SCOR Schweiz',
  },
  {
    value: 'siska_group_company_welfare',
    label: 'Personalvorsorgestiftung der SISKA-Gruppe',
  },
  {
    value: 'spross_group_company_welfare',
    label: 'Personalvorsorgestiftung der Spross Gruppe',
  },
  {
    value: 'sv_group_company_welfare',
    label: 'Personalvorsorgestiftung der SV Group',
  },
  {
    value: 'swisscard_company_welfare',
    label: 'Personalvorsorgestiftung der Swisscard',
  },
  {
    value: 'tectus_company_welfare',
    label: 'Personalvorsorgestiftung der Tectus AG in Liquidation',
  },
  {
    value: 'willy_group_company_welfare',
    label: 'Personalvorsorgestiftung der Th. Willy-Gruppe',
  },
  {
    value: 'titan_company_welfare',
    label: 'Personalvorsorgestiftung der Titan AG in Liquidation',
  },
  {
    value: 'toggenburger_company_welfare',
    label: 'Personalvorsorgestiftung der Toggenburger Unternehmungen',
  },
  {
    value: 'vetropack_group_company_welfare',
    label: 'Personalvorsorgestiftung der Vetropack-Gruppe',
  },
  {
    value: 'werner_schnellmann_treuhand_company_welfare',
    label: 'Personalvorsorgestiftung der Werner Schnellmann Treuhand AG',
  },
  {
    value: 'wilhelm_schulthess_company_welfare',
    label: 'Personalvorsorgestiftung der Wilhelm Schulthess-Stiftung',
  },
  {
    value: 'wolfensberger_group_company_welfare',
    label: 'Personalvorsorgestiftung der Wolfensberger-Gruppe',
  },
  {
    value: 'zf_services_switzerland_company_welfare',
    label: 'Personal-Vorsorgestiftung der ZF Services Schweiz AG',
  },
  {
    value: 'zurich_oberland_media_company_welfare',
    label: 'Personalvorsorgestiftung der Zürcher Oberland Medien AG',
  },
  {
    value: 'zweifel_holding_company_welfare',
    label: 'Personalvorsorgestiftung der Zweifel Holding AG',
  },
  {
    value: 'commercial_association_zurich_company_welfare',
    label:
      'Personalvorsorgestiftung des Kaufmännischen Verbandes Zürich, Zürich',
  },
  {
    value: 'sonnhalde_nursing_home_company_welfare',
    label: 'Personalvorsorgestiftung des Pflegeheims Sonnhalde',
  },
  {
    value: 'allianz_suisse_employees_company_welfare',
    label: 'Personalvorsorgestiftung für die Angestellten der Allianz Suisse',
  },
  {
    value: 'allianz_suisse_general_agency_employees_company_welfare',
    label:
      'Personalvorsorgestiftung für die Angestellten der Generalagenturen der Allianz Suisse',
  },
  {
    value: 'gietz_co_company_welfare',
    label: 'Personalvorsorgestiftung Gietz & Co. AG, Dietlikon',
  },
  {
    value: 'ivf_hartmann_company_welfare',
    label: 'Personalvorsorgestiftung IVF HARTMANN AG',
  },
  {
    value: 'la_prairie_group_company_welfare',
    label: 'Personalvorsorgestiftung La Prairie Group',
  },
  {
    value: 'linde_material_handling_company_welfare',
    label: 'Personalvorsorgestiftung Linde Material Handling Schweiz',
  },
  {
    value: 'schmibo_company_welfare',
    label: 'Personalvorsorgestiftung Schmibo',
  },
  {
    value: 'six_group_company_welfare',
    label: 'Personalvorsorgestiftung SIX Group',
  },
  {
    value: 'swissshoe_company_welfare',
    label: 'Personalvorsorgestiftung SwissShoe',
  },
  {
    value: 'vonroll_infratec_company_welfare',
    label: 'Personalvorsorgestiftung vonRoll infratec ag',
  },
  {
    value: 'ggn_community_welfare',
    label:
      'Personalwohlfahrtsstiftung der STIFTUNG GGN (Stiftung Gemeinnützige Gesellschaft von Neumünster)',
  },
  { value: 'pestalozzi_welfare', label: 'Pestalozzi-Vorsorge-Stiftung' },
  { value: 'pke_energy_pension', label: 'PKE Vorsorgestiftung Energie' },
  {
    value: 'pk_fiv_pension',
    label: 'PK-FIV, Pensionskasse Fina Interessenverband KMU in Liquidation',
  },
  {
    value: 'pk_sbv_pension',
    label: 'PK-SBV Pensionskasse Schweizerischer Baumeisterverband',
  },
  { value: 'prevas_pension', label: 'PREVAS Sammelstiftung' },
  { value: 'pro_medico_pension', label: 'Pro Medico Stiftung' },
  { value: 'profond_pension', label: 'Profond Vorsorgeeinrichtung' },
  { value: 'promea_pension', label: 'PROMEA Pensionskasse' },
  {
    value: 'protip_pension',
    label: 'ProTIP Personalvorsorgestiftung in Liquidation',
  },
  { value: 'rahn_bodmer_pension', label: 'Rahn & Bodmer-Stiftung' },
  {
    value: 'refinitiv_switzerland_pension',
    label: 'Refinitiv Switzerland Pension Fund',
  },
  { value: 'reishauer_pension', label: 'Reishauer-Stiftung' },
  {
    value: 'rendita_freizuegigkeitsstiftung',
    label: 'Rendita Freizügigkeitsstiftung',
  },
  {
    value: 'rendita_vorsorgestiftung_3a',
    label: 'Rendita Vorsorgestiftung 3a',
  },
  {
    value: 'kuoni_travel_holding_pension',
    label: 'Rentnerstiftung der Kuoni Reisen Holding AG',
  },
  { value: 'riposa_company_welfare', label: 'Riposa Personalfürsorgestiftung' },
  { value: 'rivora_pension', label: 'Rivora Sammelstiftung' },
  { value: 'rothschild_bank_pension', label: 'Rothschild & Co Bank-Stiftung' },
  {
    value: 'swiss_life_occupational_pension',
    label: 'Sammelstiftung berufliche Vorsorge Swiss Life',
  },
  {
    value: 'allianz_suisse_occupational_pension',
    label:
      'Sammelstiftung Berufliche Zusatzvorsorge der Allianz Suisse Lebensversicherungs-Gesellschaft',
  },
  { value: 'blg_pension', label: 'Sammelstiftung BLG' },
  {
    value: 'allianz_suisse_bvg_pension',
    label:
      'Sammelstiftung BVG der Allianz Suisse Lebensversicherungs-Gesellschaft',
  },
  { value: 'vita_pension', label: 'Sammelstiftung Vita' },
  {
    value: 'vita_bvg_pension',
    label:
      'Sammelstiftung Vita BVG der Zürich Lebensversicherungs-Gesellschaft AG',
  },
  {
    value: 'vita_invest_pension',
    label:
      'Sammelstiftung Vita Invest der Zürich Lebensversicherungs-Gesellschaft AG',
  },
  {
    value: 'vita_plus_pension',
    label:
      'Sammelstiftung Vita Plus der Zürich Lebensversicherungs-Gesellschaft AG',
  },
  {
    value: 'vita_select_pension',
    label:
      'Sammelstiftung Vita Select der Zürich Lebensversicherungs-Gesellschaft AG',
  },
  {
    value: 'vorsorge_switzerland_pension',
    label: 'Sammelstiftung Vorsorge der Schweiz',
  },
  {
    value: 'swiss_life_additional_pension',
    label: 'Sammelstiftung Zusatzvorsorge Swiss Life',
  },
  {
    value: 'schaub_painter_company_welfare',
    label: 'Schaub Maler AG, Fürsorgefonds',
  },
  {
    value: 'schaub_painter_company_welfare_fund',
    label: 'Schaub Maler AG, Stiftung für Personalfürsorge',
  },
  { value: 'schweiter_pension', label: 'Schweiter Vorsorgestiftung' },
  {
    value: 'seneprima_sv_group_pension',
    label: 'SenePrima-Stiftung der SV Group',
  },
  { value: 'sig_pension', label: 'SIG Pensionskasse' },
  { value: 'sig_welfare', label: 'SIG Wohlfahrtsfonds' },
  { value: 'sonova_pension', label: 'Sonova Pensionskasse' },
  { value: 'specogna_pension', label: 'Specogna Personalvorsorgestiftung' },
  {
    value: 'nzz_special_fund',
    label: 'Spezialfonds der Neuen Zürcher Zeitung',
  },
  {
    value: 'lindt_special_welfare_fund',
    label: 'Spezialfürsorgefonds der Chocoladefabriken Lindt & Sprüngli AG',
  },
  { value: 'spida_pension', label: 'Spida Personalvorsorgestiftung' },
  { value: 'ssm_pension', label: 'SSM Vorsorgestiftung in Liquidation' },
  {
    value: 'sso_pension',
    label: 'SSO-Vorsorgestiftung für zahnmedizinische Berufe',
  },
  { value: 'stewi_pension', label: 'STEWI-Stiftung' },
  {
    value: 'sanitas_health_insurance_ahv',
    label:
      'Stiftung Alters- und Hinterbliebenenversicherung der Sanitas Krankenversicherung (AHS)',
  },
  {
    value: 'insulation_trade_savings_pension',
    label: 'Stiftung Alterssparkonten Isoliergewerbe',
  },
  {
    value: 'mercedes_benz_switzerland_pension',
    label: 'Stiftung der Mercedes-Benz Schweiz AG in Liquidation',
  },
  { value: 'erne_vita_pension', label: 'Stiftung ERNE vita' },
  {
    value: 'bds_group_executive_pension',
    label: 'Stiftung für das Kader der BDS-Gruppe',
  },
  {
    value: 'flexible_retirement_fund',
    label:
      'Stiftung für den flexiblen Altersrücktritt im Bauhauptgewerbe (FAR)',
  },
  {
    value: 'allianz_suisse_additional_employee_pension',
    label:
      'Stiftung für die Zusatzvorsorge der Angestellten der Allianz Suisse',
  },
  {
    value: 'rahn_bodmer_staff_welfare',
    label: 'Stiftung für Personalfürsorge des Bankhauses Rahn & Bodmer Co.',
  },
  {
    value: 'georg_fischer_welfare',
    label: 'Stiftung für Wohlfahrtszwecke der Georg Fischer AG',
  },
  {
    value: 'lindt_chocolate_executive_pension',
    label:
      'Stiftung Kaderversicherung der Chocoladefabriken Lindt & Sprüngli AG',
  },
  { value: 'kraeuel_foundation', label: 'Stiftung Kräuel' },
  {
    value: 'meister_juwelier_pension',
    label: 'Stiftung Meister Juwelier in Liquidation',
  },
  {
    value: 'albers_employee_fund',
    label: 'Stiftung Mitarbeiterfonds der Firma Albers & Co AG, Zürich',
  },
  { value: 'waldegg_foundation', label: 'Stiftung Waldegg' },
  { value: 'golden_grape_foundation', label: 'Stiftung zur goldenen Traube' },
  {
    value: 'suisa_welfare_support',
    label: 'Stiftung zur Unterstützung der beruflichen Vorsorge der SUISA',
  },
  {
    value: 'migros_community_additional_pension',
    label: 'Stiftung Zusatzvorsorge der Migros-Gemeinschaft',
  },
  {
    value: 'goessler_employee_support',
    label:
      'Stiftungsfonds zur Unterstützung der Arbeitnehmer von H. Goessler AG, Zürich',
  },
  { value: 'sulzer_pension', label: 'Sulzer Vorsorgeeinrichtung' },
  { value: 'sunrise_pension', label: 'Sunrise Pensionskasse' },
  { value: 'suprema_pension', label: 'Suprema' },
  { value: 'swica_pension', label: 'SWICA Personalvorsorgestiftung' },
  {
    value: 'swiss_life_2nd_pillar',
    label: 'Swiss Life Sammelstiftung 2. Säule',
  },
  {
    value: 'swiss_life_invest_pension',
    label: 'Swiss Life Sammelstiftung Invest',
  },
  { value: 'swisscanto_1e_pension', label: 'Swisscanto 1e Sammelstiftung' },
  {
    value: 'swisscanto_flex_pension',
    label: 'Swisscanto Flex Sammelstiftung der Kantonalbanken',
  },
  { value: 'takeda_pension', label: 'Takeda Pension Fund' },
  { value: 'telion_pension', label: 'Telion-Stiftung' },
  { value: 'tesog_pension', label: 'TESOG Pensionskasse' },
  { value: 'tobag_pension', label: 'Tobag Stiftung' },
  { value: 'ubs_optio_1e_pension', label: 'UBS Optio 1e Sammelstiftung' },
  {
    value: 'independent_freizuegigkeitsstiftung_zurich',
    label: 'Unabhängige Freizügigkeitsstiftung Zürich',
  },
  {
    value: 'independent_community_fund_zurich',
    label: 'Unabhängige Gemeinschaftsstiftung Zürich UGZ',
  },
  {
    value: 'independent_3a_pension_zurich',
    label: 'Unabhängige Vorsorgestiftung 3a Zürich',
  },
  {
    value: 'alstom_switzerland_support',
    label: 'Unterstützungsfonds ALSTOM Schweiz',
  },
  { value: 'valitas_bvg_pension', label: 'Valitas Sammelstiftung BVG' },
  {
    value: 'swissair_flight_personnel_insurance',
    label:
      'Versicherungseinrichtung des Flugpersonals der Swissair, Schweizerische Luftverkehr Aktiengesellschaft',
  },
  {
    value: 'emil_mohr_company_welfare',
    label: 'Versicherungskasse der Emil Mohr AG',
  },
  { value: 'vetropack_buelach_pension', label: 'Vetropack-Stiftung Bülach' },
  { value: 'vinci_energies_pension', label: 'VINCI Energies Pensionskasse' },
  {
    value: 'vinci_energies_support_fund',
    label: 'VINCI Energies Unterstützungsfonds',
  },
  { value: 'vontobel_3a_pension', label: 'Vontobel 3a Vorsorgestiftung' },
  { value: 'bdo_pension', label: 'Vorsorge der BDO AG' },
  { value: 'vorsorge_in_globo_m', label: 'VORSORGE in globo M' },
  { value: 'atos_pension', label: 'Vorsorgeeinrichtung Atos (VEA)' },
  {
    value: 'goldman_sachs_pension',
    label: 'Vorsorgeeinrichtung der Goldman Sachs Bank AG',
  },
  {
    value: 'altried_schwamedingen_pension',
    label: 'Vorsorgeeinrichtung der Stiftung Altried - Schwamendingen',
  },
  {
    value: 'housing_association_switzerland_pension',
    label:
      'Vorsorgeeinrichtung von wohnbaugenossenschaften schweiz - verband der gemeinnützigen wohnbauträger',
  },
  { value: 'w_and_w_pension', label: 'Vorsorgeeinrichtung W&W' },
  {
    value: 'meier_schaffhausen_pension',
    label: 'Vorsorge-Finanzierungsfonds der Meier + Cie AG Schaffhausen',
  },
  {
    value: 'bruetsch_rueegger_holding_fund',
    label: 'Vorsorgefonds der Brütsch/Rüegger Holding AG',
  },
  {
    value: 'basler_hofmann_pension_fund',
    label: 'Vorsorgefonds der Firma Basler und Hofmann AG',
  },
  {
    value: 'fischer_architects_pension_fund',
    label: 'Vorsorgefonds der Fischer Architekten AG',
  },
  {
    value: 'mckinsey_switzerland_pension_fund',
    label:
      'Vorsorgefonds der McKinsey & Company, Inc. Switzerland, Wilmington, Zurich Branch in Liquidation',
  },
  {
    value: 'restaurant_kronenhalle_pension_fund',
    label: 'Vorsorgefonds der Restaurant Kronenhalle AG',
  },
  {
    value: 'ebp_switzerland_pension_fund',
    label: 'Vorsorgefonds EBP Schweiz AG',
  },
  { value: 'swiss_life_3a_pension', label: 'Vorsorgestiftung 3a Swiss Life' },
  {
    value: 'agi_isolation_company_welfare',
    label: 'Vorsorgestiftung der AGI AG für Isolierungen',
  },
  {
    value: 'binelli_zurich_pension',
    label: 'Vorsorgestiftung der Binelli Zürich AG in Liquidation',
  },
  {
    value: 'gazprombank_switzerland_pension',
    label: 'Vorsorgestiftung der Gazprombank (Schweiz) AG in Liquidation',
  },
  { value: 'migros_bank_pension', label: 'Vorsorgestiftung der Migros Bank' },
  {
    value: 'pewag_suisse_pension',
    label: 'Vorsorgestiftung der pewag Suisse AG',
  },
  {
    value: 'schroder_co_bank_pension',
    label: 'Vorsorgestiftung der Schroder & Co Bank AG Zürich',
  },
  {
    value: 'machinery_association_pension',
    label: 'Vorsorgestiftung der Verbände der Maschinenindustrie',
  },
  {
    value: 'commercial_association_switzerland_pension',
    label: 'Vorsorgestiftung des Kaufmännischen Verbandes Schweiz',
  },
  {
    value: 'swiss_missionary_association_pension',
    label: 'Vorsorgestiftung des Verein Schweizerische Glaubensmission',
  },
  {
    value: 'enzler_cleaning_pension',
    label: 'Vorsorgestiftung Enzler-Reinigungen AG',
  },
  {
    value: 'film_audio_pension',
    label: 'Vorsorgestiftung Film und Audiovision',
  },
  {
    value: 'swissavant_pension',
    label:
      'Vorsorgestiftung für das Personal von Swissavant - Wirtschaftsverband Handwerk und Haushalt',
  },
  {
    value: 'hauenstein_company_welfare',
    label: 'Vorsorgestiftung Hauenstein AG',
  },
  {
    value: 'shkb_3a_pension',
    label: 'Vorsorgestiftung Sparen 3 der Schaffhauser Kantonalbank',
  },
  {
    value: 'zkb_3a_pension',
    label: 'Vorsorgestiftung Sparen 3 der Zürcher Kantonalbank',
  },
  {
    value: 'staeubli_switzerland_pension',
    label: 'Vorsorgestiftung Stäubli Schweiz',
  },
  {
    value: 'staeubli_switzerland_additional_pension',
    label: 'Vorsorgestiftung Stäubli Schweiz Zusatzkasse',
  },
  {
    value: 'swiss_life_personal_pension',
    label: 'Vorsorgestiftung Swiss Life Personal',
  },
  {
    value: 'swiss_life_personal_additional_pension',
    label: 'Vorsorgestiftung Swiss Life Personal Zusatzversicherung',
  },
  {
    value: 'zurich_lawyers_pension',
    label: 'Vorsorgestiftung Zürcher Anwaltsverband',
  },
  { value: 'vz_bvg_pension', label: 'VZ BVG Sammelstiftung' },
  { value: 'vz_freizuegigkeitsstiftung', label: 'VZ Freizügigkeitsstiftung' },
  { value: 'vz_pension_fund', label: 'VZ Sammelstiftung' },
  {
    value: 'vz_3a_pension',
    label:
      'VZ Vorsorgestiftung 3a (VZ Fondation de Prévoyance 3a) (VZ Fondazione di Previdenza 3a)',
  },
  {
    value: 'walter_jenny_pension',
    label: 'Walter Jenny-Stiftung in Liquidation',
  },
  {
    value: 'walter_reist_personal_pension',
    label: 'Walter Reist Personalvorsorgestiftung',
  },
  {
    value: 'walter_reist_welfare_pension',
    label: 'Walter Reist Wohlfahrtsstiftung',
  },
  { value: 'willy_kuendig_pension', label: 'Willy Kündig-Stiftung' },
  {
    value: 'university_zurich_professors_pension',
    label:
      'Witwen-, Waisen- und Pensionskasse der Professoren der Universität Zürich',
  },
  { value: 'alphaplan_welfare_fund', label: 'Wohlfahrtsfonds Alphaplan' },
  {
    value: 'bank_julius_baer_welfare_fund',
    label: 'Wohlfahrtsfonds der Bank Julius Bär & Co AG',
  },
  {
    value: 'baugenossenschaft_st_jakob_welfare_fund',
    label: 'Wohlfahrtsfonds der Baugenossenschaft St. Jakob',
  },
  {
    value: 'baumann_koelliker_welfare_fund',
    label: 'Wohlfahrtsfonds der Baumann Koelliker Gruppe',
  },
  {
    value: 'boller_winkler_welfare_fund',
    label: 'Wohlfahrtsfonds der Boller Winkler Gruppe',
  },
  {
    value: 'dolder_hotel_welfare_fund',
    label: 'Wohlfahrtsfonds der Dolder Hotel AG',
  },
  {
    value: 'electrolux_welfare_fund',
    label: 'Wohlfahrtsfonds der Electrolux AG',
  },
  { value: 'elektron_welfare_fund', label: 'Wohlfahrtsfonds der Elektron AG' },
  {
    value: 'buehlmann_heating_welfare_fund',
    label: 'Wohlfahrtsfonds der Firma Bühlmann Heizungen AG, Zürich',
  },
  {
    value: 'ganz_baukeramik_welfare_fund',
    label: 'Wohlfahrtsfonds der Firma Ganz Baukeramik AG',
  },
  {
    value: 'gossweiler_engineering_welfare_fund',
    label: 'Wohlfahrtsfonds der Firma Gossweiler Ingenieure AG',
  },
  {
    value: 'otto_honegger_welfare_fund',
    label: 'Wohlfahrtsfonds der Firma Otto und Joh. Honegger AG',
  },
  {
    value: 'paul_reinhart_welfare_fund',
    label: 'Wohlfahrtsfonds der Firma Paul Reinhart AG',
  },
  {
    value: 'hermes_sweetener_welfare_fund',
    label: 'Wohlfahrtsfonds der Hermes Süssstoff AG',
  },
  {
    value: 'hinderer_group_welfare_fund',
    label: 'Wohlfahrtsfonds der Hinderer Gruppe',
  },
  {
    value: 'hoerburger_holding_welfare_fund',
    label: 'Wohlfahrtsfonds der Hörburger Holding AG',
  },
  {
    value: 'hunziker_ag_welfare_fund',
    label: 'Wohlfahrtsfonds der Hunziker AG Thalwil',
  },
  {
    value: 'kessler_welfare_fund',
    label: 'Wohlfahrtsfonds der Kessler & Co AG',
  },
  {
    value: 'loacker_recycling_welfare_fund',
    label: 'Wohlfahrtsfonds der Loacker Swiss Recycling AG',
  },
  {
    value: 'lufttechnik_ag_welfare_fund',
    label: 'Wohlfahrtsfonds der Lufttechnik AG',
  },
  {
    value: 'meier_tobler_welfare_fund',
    label: 'Wohlfahrtsfonds der Meier Tobler',
  },
  { value: 'modissa_welfare_fund', label: 'Wohlfahrtsfonds der Modissa AG' },
  {
    value: 'oscar_eberli_welfare_fund',
    label: 'Wohlfahrtsfonds der Oscar Eberli AG',
  },
  {
    value: 'oscar_weber_welfare_fund',
    label: 'Wohlfahrtsfonds der Oscar Weber AG',
  },
  { value: 'schibli_welfare_fund', label: 'Wohlfahrtsfonds der Schibli AG' },
  {
    value: 'rega_rescue_welfare_fund',
    label: 'Wohlfahrtsfonds der Schweizerischen Rettungsflugwacht, Rega',
  },
  {
    value: 'ssi_schaefer_welfare_fund',
    label: 'Wohlfahrtsfonds der SSI Schäfer AG',
  },
  { value: 'tx_group_welfare_fund', label: 'Wohlfahrtsfonds der TX-Group AG' },
  {
    value: 'walter_meier_manufacturing_welfare_fund',
    label: 'Wohlfahrtsfonds der Walter Meier (Fertigungslösungen) AG',
  },
  {
    value: 'welti_furrer_welfare_fund',
    label: 'Wohlfahrtsfonds der Welti-Furrer AG',
  },
  {
    value: 'zellweger_luwa_welfare_fund',
    label: 'Wohlfahrtsfonds der Zellweger Luwa AG',
  },
  {
    value: 'robert_aebi_employees_welfare_fund',
    label: 'Wohlfahrtsfonds für die Angestellten der Robert Aebi AG',
  },
  {
    value: 'siemens_switzerland_employees_welfare_fund',
    label: 'Wohlfahrtsfonds für die Mitarbeiter der Siemens Schweiz AG',
  },
  {
    value: 'kracht_erben_welfare_fund',
    label: "Wohlfahrtsfonds H. Kracht's Erben",
  },
  {
    value: 'otto_fischer_welfare_fund',
    label: 'Wohlfahrtsfonds Otto Fischer AG',
  },
  {
    value: 'schaeppi_real_estate_welfare_fund',
    label: 'Wohlfahrtsfonds Schaeppi Grundstücke und Tochtergesellschaften',
  },
  { value: 'sulzer_welfare_fund', label: 'Wohlfahrtsfonds Sulzer' },
  {
    value: 'unilever_switzerland_welfare_fund',
    label: 'Wohlfahrtsfonds Unilever Schweiz GmbH',
  },
  {
    value: 'bayer_switzerland_welfare_fund',
    label: 'Wohlfahrtsstiftung der Bayer (Schweiz) AG in Liquidation',
  },
  {
    value: 'bmw_switzerland_welfare_fund',
    label: 'Wohlfahrtsstiftung der BMW (Schweiz) AG',
  },
  {
    value: 'falken_brewery_welfare_fund',
    label: 'Wohlfahrtsstiftung der Brauerei Falken AG Schaffhausen',
  },
  {
    value: 'neuapostolische_church_switzerland_welfare_fund',
    label: 'Wohlfahrtsstiftung der Neuapostolischen Kirche Schweiz',
  },
  {
    value: 'scania_switzerland_welfare_fund',
    label: 'Wohlfahrtsstiftung der Scania Schweiz AG',
  },
  {
    value: 'sparcassa_1816_welfare_fund',
    label: 'Wohlfahrtsstiftung der Sparcassa 1816',
  },
  {
    value: 'volksapotheke_schaffhausen_welfare_fund',
    label:
      'Wohlfahrtsstiftung der Volksapotheke Schaffhausen und Umgebung in Liquidation',
  },
  {
    value: 'zurich_oberland_media_welfare_fund',
    label: 'Wohlfahrtsstiftung der Zürcher Oberland Medien AG',
  },
  {
    value: 'ivf_hartmann_welfare_fund',
    label: 'Wohlfahrtsstiftung IVF HARTMANN AG',
  },
  {
    value: 'sps_jelmoli_welfare_fund',
    label: 'Wohlfahrtsstiftung SPS und Jelmoli',
  },
  {
    value: 'swiss_life_internal_external_service_welfare_fund',
    label: 'Wohlfahrtsstiftung Swiss Life Innen- und Aussendienst',
  },
  { value: 'swissmem_welfare_fund', label: 'Wohlfahrtsstiftung Swissmem' },
  {
    value: 'zurich_invest_bank_foundation',
    label:
      'Zurich Invest Bankstiftung, Zurich Invest Fondation Bancaire, Zurich Invest Fondazione Bancaria, Zurich Invest Bank Foundation',
  },
  {
    value: 'zurich_invest_freizuegigkeitsstiftung',
    label: 'Zurich Invest Freizügigkeitsstiftung',
  },
  {
    value: 'bank_vontobel_additional_pension',
    label: 'Zusatzkasse der Bank Vontobel AG',
  },
  {
    value: 'orior_group_additional_pension',
    label: 'Zusatzkasse der Orior Gruppe',
  },
  {
    value: 'sr_technics_additional_pension',
    label: 'Zusatzkasse der SR Technics Switzerland in Liquidation',
  },
  {
    value: 'bruetsch_rueegger_additional_pension',
    label: 'Zusatzstiftung der Brütsch/Rüegger Holding AG',
  },
  {
    value: 'coutts_bank_additional_pension',
    label: 'Zusatzstiftung der Coutts & Co AG',
  },
];

export default pensionFunds;
