import * as Yup from 'yup';

export const insurancePolicySchema = Yup.object().shape({
  insurance_type: Yup.string().required(
    'validations.importantInformation.insurancePolicySchema.insurance_type'
  ),
  insurance_company: Yup.string().required(
    'validations.importantInformation.insurancePolicySchema.insurance_company'
  ),
  policy_number: Yup.string().required(
    'validations.importantInformation.insurancePolicySchema.policy_number'
  ),
  // storage_location: Yup.string().required(
  //   'validations.importantInformation.insurancePolicySchema.storage_location'
  // ),
});

export const threeASchema = Yup.object().shape({
  pension_type: Yup.string().required(
    'validations.importantInformation.threeASchema.pension_type'
  ),
  financial_institution: Yup.string().required(
    'validations.importantInformation.threeASchema.financial_institution'
  ),
  // storage_location: Yup.string().required(
  //   'validations.importantInformation.threeASchema.storage_location'
  // ),
});

export const notarySchema = Yup.object().shape({
  name: Yup.string().required(
    'validations.importantInformation.notarySchema.name'
  ),
  email: Yup.string()
    .email('validations.importantInformation.notarySchema.invalidEmail')
    .required('validations.importantInformation.notarySchema.email'),
});
