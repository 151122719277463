const insuranceTypes = [
  { value: 'life_insurance', label: 'life_insurance' },
  {
    value: 'private_liability_insurance',
    label: 'private_liability_insurance',
  },
  { value: 'household_insurance', label: 'household_insurance' },
  { value: 'vehicle_insurance', label: 'vehicle_insurance' },
  { value: 'building_insurance', label: 'building_insurance' },
  { value: 'legal_expenses_insurance', label: 'legal_expenses_insurance' },
  {
    value: 'occupational_disability_insurance',
    label: 'occupational_disability_insurance',
  },
  { value: 'health_insurance', label: 'health_insurance' },
  {
    value: 'supplementary_health_insurance',
    label: 'supplementary_health_insurance',
  },
  { value: 'accident_insurance', label: 'accident_insurance' },
];

export default insuranceTypes;
