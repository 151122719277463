import React, { useState, useRef } from 'react';
import { Button, Stack, Typography, Box, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '#/components/shared/ui/Iconify';
import useLocales from '#/hooks/useLocales';
import {
  invalidateImportantInformationQueries,
  useDeleteInsurance,
} from '#/api/importantInformation';
import { InsurancePolicy } from '#/types/importantInformation';
import ConfirmDeleteModal from '#/components/pages/ImportantInformation/steps/FinancialInformations/insurance-policies/confirm-delete-modal';
import useIsTextTruncated from '#/hooks/useIsTextTruncated';

type Props = {
  insurance: InsurancePolicy;
  handleEditMode: (id: number) => void;
  readOnly?: boolean;
};

export default function InsurancePolicyDetails({
  insurance,
  handleEditMode,
  readOnly,
}: Props) {
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const { translate } = useLocales();
  const { mutateAsync: deleteInsurance, isLoading: deleting } =
    useDeleteInsurance(insurance.id);

  const insuranceTypeLabel = String(
    translate(`importantInformation.insuranceTypes.${insurance.insurance_type}`)
  );

  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const handleDelete = async () => {
    await deleteInsurance(insurance.id).then(() => {
      invalidateImportantInformationQueries.getInsurance();
    });
    handleCloseDeleteModal();
  };

  const insuranceTypeRef = useRef<HTMLDivElement>(null);
  const insuranceCompanyRef = useRef<HTMLDivElement>(null);
  const policyNumberRef = useRef<HTMLDivElement>(null);
  const storageLocationRef = useRef<HTMLDivElement>(null);

  const isInsuranceTypeTruncated = useIsTextTruncated(insuranceTypeRef);
  const isInsuranceCompanyTruncated = useIsTextTruncated(insuranceCompanyRef);
  const isPolicyNumberTruncated = useIsTextTruncated(policyNumberRef);
  const isStorageLocationTruncated = useIsTextTruncated(storageLocationRef);

  return (
    <Box sx={{ my: 1 }}>
      <Stack
        direction={{
          xs: 'column',
          sm: 'row',
        }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack
          direction={{
            xs: 'column',
            sm: 'row',
          }}
          spacing={3}
        >
          <Tooltip
            title={insuranceTypeLabel}
            disableHoverListener={!isInsuranceTypeTruncated}
          >
            <Typography
              variant="subtitle1"
              fontWeight="light"
              sx={{ width: '170px' }}
              noWrap
              ref={insuranceTypeRef}
            >
              {insuranceTypeLabel}
            </Typography>
          </Tooltip>
          <Tooltip
            title={insurance.insurance_company}
            disableHoverListener={!isInsuranceCompanyTruncated}
          >
            <Typography
              variant="subtitle1"
              fontWeight="light"
              sx={{ width: '120px' }}
              noWrap
              ref={insuranceCompanyRef}
            >
              {insurance.insurance_company}
            </Typography>
          </Tooltip>
          <Tooltip
            title={insurance.policy_number}
            disableHoverListener={!isPolicyNumberTruncated}
          >
            <Typography
              variant="subtitle1"
              fontWeight="light"
              sx={{ width: '170px' }}
              noWrap
              ref={policyNumberRef}
            >
              {insurance.policy_number}
            </Typography>
          </Tooltip>
          <Tooltip
            title={insurance.storage_location}
            disableHoverListener={!isStorageLocationTruncated}
          >
            <Typography
              variant="subtitle1"
              fontWeight="light"
              sx={{ width: '170px' }}
              noWrap
              ref={storageLocationRef}
            >
              {insurance.storage_location}
            </Typography>
          </Tooltip>
        </Stack>

        {!readOnly && (
          <Stack direction="row" spacing={1}>
            <Button
              size="small"
              variant="outlined"
              startIcon={
                <Iconify icon="octicon:pencil-16" sx={{ fontSize: '1.5rem' }} />
              }
              onClick={() => handleEditMode(insurance.id)}
            >
              <Typography variant="subtitle2" fontWeight="light">
                {String(translate('global.edit'))}
              </Typography>
            </Button>
            <LoadingButton
              size="small"
              variant="outlined"
              color="error"
              startIcon={
                <Iconify icon="octicon:trash-16" sx={{ fontSize: '1.5rem' }} />
              }
              onClick={handleOpenDeleteModal}
              loading={deleting}
            >
              <Typography variant="subtitle2" fontWeight="light">
                {String(translate('global.delete'))}
              </Typography>
            </LoadingButton>
          </Stack>
        )}
      </Stack>
      <ConfirmDeleteModal
        open={openDeleteModal}
        closeDialog={handleCloseDeleteModal}
        onConfirm={handleDelete}
        policy={insuranceTypeLabel}
      />
    </Box>
  );
}
