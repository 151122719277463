import { Button, MenuItem, Stack, Typography } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormProvider,
  RHFSelect,
  RHFTextField,
} from '#/components/shared/hook-form';
import insuranceTypes from '#/components/pages/ImportantInformation/steps/FinancialInformations/insurance-policies/insuranceTypes';
import useLocales from '#/hooks/useLocales';
import { InsurancePolicy } from '#/types/importantInformation';
import {
  invalidateImportantInformationQueries,
  useAddInsurance,
  useGetInsuranceById,
  useUpdateInsurance,
} from '#/api/importantInformation';
import { insurancePolicySchema } from '#/components/pages/ImportantInformation/steps/schemas';

type Props = {
  editMode?: boolean | number;
  resetEditMode?: () => void;
  resetAddMore?: () => void;
};

export default function InsurancePolicyForm({
  editMode,
  resetEditMode,
  resetAddMore,
}: Props) {
  const { mutateAsync: addInsurance, isLoading: adding } = useAddInsurance();
  const { data: insurance } = useGetInsuranceById(Number(editMode), !!editMode);
  const { mutateAsync: updateInsurance, isLoading: updating } =
    useUpdateInsurance(Number(editMode));

  const { watch } = useFormContext();
  const serviceID = watch('id');

  const defaultValues = useMemo(
    () => ({
      important_information: serviceID,
      insurance_type: insurance?.insurance_type || '',
      insurance_company: insurance?.insurance_company || '',
      policy_number: insurance?.policy_number || '',
      storage_location: insurance?.storage_location || '',
    }),
    [insurance, watch('important_information'), serviceID]
  );

  const methods = useForm<InsurancePolicy>({
    // @ts-ignore
    resolver: yupResolver(insurancePolicySchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isDirty },
    reset,
  } = methods;

  const disableSubmit = !isDirty;

  const { translate } = useLocales();

  const onSubmit = async (data: InsurancePolicy) => {
    try {
      if (editMode) {
        await updateInsurance(data).then(() => {
          resetEditMode?.();
          invalidateImportantInformationQueries.getInsuranceById(
            Number(editMode)
          );
          invalidateImportantInformationQueries.getInsurance();
        });
      } else {
        await addInsurance(data).then(() => {
          resetAddMore?.();
          invalidateImportantInformationQueries.getInsurance();
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (insurance || serviceID) {
      reset(defaultValues);
    }
  }, [insurance, serviceID]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack
        direction={{
          xs: 'column',
          sm: 'row',
        }}
        spacing={2}
      >
        <RHFSelect
          name="insurance_type"
          label={String(translate('global.formLabels.type'))}
        >
          {insuranceTypes.map((type) => (
            <MenuItem key={type.value} value={type.value}>
              {String(
                translate(`importantInformation.insuranceTypes.${type.label}`)
              )}
            </MenuItem>
          ))}
        </RHFSelect>
        <RHFTextField
          name="insurance_company"
          label={String(translate('global.formLabels.insuranceCompany'))}
        />
        <RHFTextField
          name="policy_number"
          label={String(translate('global.formLabels.insurancePolicyNumber'))}
        />
        <RHFTextField
          name="storage_location"
          label={String(translate('global.formLabels.storageLocation'))}
        />
      </Stack>
      <Stack
        direction={{
          xs: 'column',
          sm: 'row',
        }}
        spacing={2}
        justifyContent="flex-end"
        sx={{ mt: 2 }}
      >
        <Button
          variant="contained"
          color="inherit"
          onClick={() => {
            if (editMode) {
              resetEditMode?.();
            } else {
              resetAddMore?.();
            }
          }}
        >
          <Typography variant="subtitle2" fontWeight="light">
            {String(translate('global.cancel'))}
          </Typography>
        </Button>
        <LoadingButton
          loading={adding || updating}
          variant="contained"
          color="primary"
          type="submit"
          disabled={disableSubmit}
          onClick={(e) => {
            e.preventDefault();
            handleSubmit(onSubmit)();
          }}
        >
          <Typography variant="subtitle2" fontWeight="light">
            {String(translate('global.save'))}
          </Typography>
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
