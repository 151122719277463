const ahvFunds = [
  { value: 'sva_zurich', label: 'SVA Zürich' },
  {
    value: 'compensation_fund_of_canton_bern',
    label: 'Ausgleichskasse des Kantons Bern',
  },
  { value: 'ahv_branch_of_city_bern', label: 'AHV-Zweigstelle der Stadt Bern' },
  { value: 'ahv_branch_biel', label: 'AHV-Zweigstelle Biel' },
  {
    value: 'ahv_branch_state_personnel',
    label: 'AHV-Zweigstelle Staatspersonal',
  },
  {
    value: 'was_economy_work_socials_compensation_fund_lucerne',
    label: 'WAS Wirtschaft Arbeit Soziales Ausgleichskasse Luzern',
  },
  {
    value: 'social_security_office_uri',
    label: 'Sozialversicherungsstelle Uri',
  },
  {
    value: 'compensation_fund_and_iv_office_schwyz',
    label: 'Ausgleichskasse und IV-Stelle Schwyz',
  },
  {
    value: 'compensation_fund_and_iv_office_obwalden',
    label: 'Ausgleichskasse und IV-Stelle Obwalden',
  },
  { value: 'compensation_fund_nidwalden', label: 'Ausgleichskasse Nidwalden' },
  { value: 'social_insurances_glarus', label: 'Sozialversicherungen Glarus' },
  { value: 'compensation_fund_zug', label: 'Ausgleichskasse Zug' },
  {
    value: 'compensation_fund_of_canton_freiburg',
    label: 'Ausgleichskasse des Kantons Freiburg',
  },
  {
    value: 'compensation_fund_of_canton_solothurn',
    label: 'Ausgleichskasse des Kantons Solothurn',
  },
  {
    value: 'compensation_fund_basel_city',
    label: 'Ausgleichskasse Basel-Stadt',
  },
  { value: 'sva_basel_landscape', label: 'SVA Basel-Landschaft' },
  { value: 'sva_schaffhausen', label: 'SVA Schaffhausen' },
  {
    value: 'compensation_fund_appenzell_outer_rhodes',
    label: 'Ausgleichskasse Appenzell Ausserrhoden',
  },
  {
    value: 'compensation_fund_appenzell_inner_rhodes',
    label: 'Ausgleichskasse Appenzell Innerrhoden',
  },
  {
    value: 'social_security_institution_of_canton_st_gallen',
    label: 'Sozialversicherungsanstalt des Kantons St. Gallen',
  },
  {
    value: 'social_security_institution_of_canton_graubünden',
    label: 'Sozialversicherungsanstalt des Kantons Graubünden',
  },
  { value: 'sva_aargau', label: 'SVA Aargau' },
  {
    value: 'social_security_center_thurgau',
    label: 'Sozialversicherungszentrum Thurgau',
  },
  {
    value: 'institute_of_social_insurances',
    label: 'Istituto delle assicurazioni sociali',
  },
  {
    value: 'cantonal_compensation_fund_vaud',
    label: 'Caisse cantonale vaudoise de compensation',
  },
  {
    value: 'social_insurance_service',
    label: 'Service des assurances sociales',
  },
  {
    value: 'compensation_fund_of_canton_valais',
    label: 'Ausgleichskasse des Kantons Wallis',
  },
  {
    value: 'compensation_fund_of_canton_neuchâtel',
    label: 'Ausgleichskasse des Kantons Neuenburg',
  },
  {
    value: 'cantonal_social_insurance_office',
    label: 'Office cantonal des assurances sociales',
  },
  {
    value: 'federal_compensation_fund_eak',
    label: 'Eidgenössische Ausgleichskasse EAK',
  },
  {
    value: 'swiss_compensation_fund_sak',
    label: 'Schweizerische Ausgleichskasse SAK',
  },
  {
    value: 'compensation_fund_of_canton_jura',
    label: 'Caisse de compensation du Canton du Jura',
  },
  {
    value: 'compensation_fund_medisuisse',
    label: 'Ausgleichskasse medisuisse',
  },
  { value: 'compensation_fund_simulac', label: 'Ausgleichskasse Simulac' },
  { value: 'compensation_fund_coop', label: 'Ausgleichskasse Coop' },
  {
    value: 'eastern_swiss_compensation_fund_for_trade_and_industry',
    label: 'Ostschweizerische Ausgleichskasse für Handel und Industrie',
  },
  { value: 'compensation_fund_mobil', label: 'Ausgleichskasse MOBIL' },
  {
    value: 'ahv_compensation_fund_butcher',
    label: 'AHV-Ausgleichskasse Metzger',
  },
  {
    value: 'compensation_fund_scienceindustries',
    label: 'Ausgleichskasse scienceINDUSTRIES',
  },
  {
    value: 'compensation_fund_swiss_electricity_companies',
    label: 'Ausgleichskasse Schweizerischer Elektrizitätswerke',
  },
  { value: 'compensation_fund_panvica', label: 'Ausgleichskasse PANVICA' },
  {
    value: 'compensation_fund_employers_basel',
    label: 'Ausgleichskasse Arbeitgeber Basel',
  },
  { value: 'hotela', label: 'Hotela' },
  {
    value: 'gastrosocial_compensation_fund',
    label: 'GastroSocial Ausgleichskasse',
  },
  {
    value: 'gastrosocial_compensation_fund_italian',
    label: 'GastroSocial Cassa di compensazione',
  },
  { value: 'compensation_fund_ahik', label: 'Ausgleichskasse der AIHK' },
  {
    value: 'compensation_fund_watchmaking_industry_cchi',
    label: 'Ausgleichskasse der Uhrenindustrie CCHI',
  },
  {
    value: 'compensation_fund_watchmaking_industry',
    label: 'Ausgleichskasse der Uhrenindustrie',
  },
  {
    value: 'compensation_fund_avs_of_watchmaking_industry',
    label: "Caisse de compensation AVS de l'industrie horlogère",
  },
  {
    value: 'compensation_fund_thurgau_trade_association',
    label: 'Ausgleichskasse des Thurgauer Gewerbeverbandes',
  },
  {
    value: 'interprofessional_compensation_funds_neuchâtel',
    label: 'Caisses interprofessionnelles neuchâteloise de compensation',
  },
  { value: 'compensation_fund_swissmem', label: 'Ausgleichskasse Swissmem' },
  {
    value: 'compensation_fund_node_avs',
    label: 'Caisse de compensation NODE AVS',
  },
  {
    value: 'compensation_fund_bern_employers',
    label: 'Ausgleichskasse Berner Arbeitgeber',
  },
  { value: 'social_funds_cvci', label: 'Caisses sociales de la CVCI' },
  {
    value: 'avs_fund_vaud_employer_federation',
    label: 'Caisse AVS de la Fédération patronale vaudoise',
  },
  { value: 'meroba', label: 'Meroba' },
  { value: 'meroba_agency_lausanne', label: 'Meroba - Agentur Lausanne' },
  { value: 'meroba_agency_sion', label: 'Meroba - Agentur Sitten' },
  {
    value: 'compensation_fund_trade_st_gallen',
    label: 'Ausgleichskasse Gewerbe St. Gallen',
  },
  {
    value: 'compensation_fund_hairdressing_esthetics',
    label: 'Ausgleichskasse Coiffure & Esthétique',
  },
  {
    value: 'compensation_fund_business_association_baselland',
    label: 'Ausgleichskasse Wirtschaftskammer Baselland',
  },
  {
    value: 'compensation_fund_private_clinics',
    label: 'Ausgleichskasse Privatkliniken',
  },
  {
    value:
      'compensation_fund_agricultural_viticultural_and_rural_avs_agrivit_116',
    label:
      "Caisse de compensation AVS agricole, viticole et rurale 'Agrivit' 116",
  },
  {
    value: 'consimo_compensation_fund_117_swisstempcomp',
    label: 'consimo - Ausgleichskasse 117 swisstempcomp',
  },
];

export default ahvFunds;
