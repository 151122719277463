import { useLocation } from 'react-router-dom';
import useLocales from '#/hooks/useLocales';
import pensionFunds from '#/components/pages/ImportantInformation/steps/FinancialInformations/pensionFunds';
import RHFAutocomplete from '#/components/shared/hook-form/RHFAutocomplete';
import useLocalStorage from '#/hooks/useLocalStorage';

export default function PensionFund() {
  const { translate } = useLocales();
  const { state } = useLocation();
  const [ownerID] = useLocalStorage('ownerID', state?.ownerID);

  return (
    <RHFAutocomplete
      name="pension_fund_organization"
      options={pensionFunds}
      label={String(translate('global.formLabels.pensionFundOrganization'))}
      disabled={ownerID}
    />
  );
}
