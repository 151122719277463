import {
  Box,
  FormHelperText,
  MenuItem,
  Typography,
  Stack,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import Iconify from '#/components/shared/ui/Iconify';
import { RHFSelect } from '#/components/shared/hook-form';
import useLocales from '#/hooks/useLocales';
import RepresentativesForm from '#/components/pages/LivingWill/Steps/content/Representatives/RepresentativesForm';
import { useGetRepresentatives } from '#/api/representativeQueries';
import useLocalStorage from '#/hooks/useLocalStorage';

type Props = {
  error?: string;
};

const TrustedPersonForm = ({ error }: Props) => {
  const [lastAdded, setLastAdded] = useState<string | null>(null);
  const [addMore, setAddMore] = useState(false);

  const { data: representatives } = useGetRepresentatives();

  const { resetField, setValue } = useFormContext();

  const { translate } = useLocales();
  const { state } = useLocation();

  const [ownerID] = useLocalStorage('ownerID', state?.ownerID);

  const handleAddMore = () => {
    setAddMore((prev) => !prev);
  };

  const selectLastAdded = () => {
    if (lastAdded) {
      setValue('trusted_person', lastAdded);
    }
  };

  useEffect(() => {
    selectLastAdded();
  }, [lastAdded]);

  return (
    <Box
      sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}
    >
      {!addMore && (
        <Stack direction="column" gap={4}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <RHFSelect
              name="trusted_person"
              label={String(translate('global.formLabels.trustedPerson'))}
              disabled={ownerID}
            >
              <MenuItem
                onClick={() => {
                  resetField(`trusted_person`);
                  handleAddMore();
                }}
              >
                <Iconify icon="mdi:account-plus" color="text.secondary" />
                <Typography
                  variant="caption"
                  sx={{
                    textTransform: 'none',
                  }}
                >
                  {String(
                    translate(
                      'importantInformation.stepThree.addAnotherTrustedPerson'
                    )
                  )}
                </Typography>
              </MenuItem>
              {representatives?.map((rep) => (
                <MenuItem key={rep.id} value={rep.id}>
                  {rep?.first_name} {rep?.last_name}
                </MenuItem>
              ))}
            </RHFSelect>
          </Stack>
        </Stack>
      )}

      {addMore && (
        <RepresentativesForm
          handleAddMore={handleAddMore}
          handleLastAdded={setLastAdded}
        />
      )}
      {!!error && addMore && (
        <FormHelperText error={!!error} sx={{ mx: 0 }}>
          {error && error}
        </FormHelperText>
      )}
    </Box>
  );
};

export default TrustedPersonForm;
