import { Button, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import useLocales from '#/hooks/useLocales';
import Iconify from '#/components/shared/ui/Iconify';
import NotaryForm from '#/components/pages/ImportantInformation/steps/TrustedPerson/notary/notary-form';
import NotaryDetails from '#/components/pages/ImportantInformation/steps/TrustedPerson/notary/notary-details';
import { useGetNotary } from '#/api/importantInformation';
import useLocalStorage from '#/hooks/useLocalStorage';

export default function Notary() {
  const [enableForm, setEnableForm] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean | number>(false);
  const [addMore, setAddMore] = useState<boolean>(false);

  const { state } = useLocation();

  const [ownerID] = useLocalStorage('ownerID', state?.ownerID);

  const { data: notaries } = useGetNotary(ownerID || undefined);

  const handleEnableForm = () => setEnableForm(true);
  const resetEnableForm = () => setEnableForm(false);

  const handleEditMode = (id: number) => setEditMode(id);
  const resetEditMode = () => setEditMode(false);

  const handleAddMore = () => setAddMore(true);
  const resetAddMore = () => setAddMore(false);

  const isInitial = notaries?.results?.length === 0;

  const { translate } = useLocales();

  if (ownerID) {
    if (notaries?.results?.length === 0) {
      return (
        <Typography variant="subtitle1" fontWeight="light">
          {String(translate('global.noData'))}
        </Typography>
      );
    }
    return notaries?.results?.map((notary, index) => (
      <NotaryDetails
        key={index}
        notary={notary}
        handleEditMode={handleEditMode}
        readOnly
      />
    ));
  }

  if (enableForm || editMode || addMore) {
    return (
      <NotaryForm
        editID={editMode}
        resetEnableForm={resetEnableForm}
        resetEditMode={resetEditMode}
        resetAddMore={resetAddMore}
      />
    );
  }

  if (isInitial) {
    return (
      <Button
        variant="contained"
        onClick={handleEnableForm}
        startIcon={<Iconify icon="mdi:account-plus" />}
      >
        <Typography variant="subtitle2" fontWeight="light">
          {String(translate('importantInformation.stepThree.addNotary'))}
        </Typography>
      </Button>
    );
  }

  return (
    <>
      {notaries?.results?.map((notary, index) => (
        <NotaryDetails
          key={index}
          notary={notary}
          handleEditMode={handleEditMode}
        />
      ))}
      <Stack direction="row" justifyContent="flex-end" sx={{ marginTop: 2 }}>
        <Button
          variant="contained"
          onClick={handleAddMore}
          startIcon={<Iconify icon="mdi:account-plus" />}
        >
          <Typography variant="subtitle2" fontWeight="light">
            {String(translate('importantInformation.stepThree.addNotary'))}
          </Typography>
        </Button>
      </Stack>
    </>
  );
}
