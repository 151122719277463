import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { RHFTextField } from '#/components/shared/hook-form';
import useLocales from '#/hooks/useLocales';
import useLocalStorage from '#/hooks/useLocalStorage';

export default function BankDocuments() {
  const { translate } = useLocales();
  const { state } = useLocation();

  const [ownerID] = useLocalStorage('ownerID', state?.ownerID);

  return (
    <Box>
      <RHFTextField
        name="bank_documents_storage_location"
        label={String(translate('global.formLabels.storageLocation'))}
        disabled={ownerID}
      />
    </Box>
  );
}
