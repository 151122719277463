import { Button, MenuItem, Stack, Typography } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormProvider,
  RHFSelect,
  RHFTextField,
} from '#/components/shared/hook-form';
import useLocales from '#/hooks/useLocales';
import { ThreeA } from '#/types/importantInformation';
import {
  invalidateImportantInformationQueries,
  useAdd3A,
  useGet3AById,
  useUpdate3A,
} from '#/api/importantInformation';
import { threeASchema } from '#/components/pages/ImportantInformation/steps/schemas';

type Props = {
  editMode?: boolean | number;
  resetEditMode?: () => void;
  resetAddMore?: () => void;
};

const threeATypes = [
  {
    value: 'policy',
  },
  {
    value: 'fund',
  },
  {
    value: 'account',
  },
];

export default function ThreeAForm({
  editMode,
  resetEditMode,
  resetAddMore,
}: Props) {
  const { mutateAsync: addThreeA, isLoading: adding } = useAdd3A();
  const { data: threeA } = useGet3AById(Number(editMode), !!editMode);
  const { mutateAsync: updateThreeA, isLoading: updating } = useUpdate3A(
    Number(editMode)
  );

  const { watch } = useFormContext();
  const serviceID = watch('id');

  const defaultValues = useMemo(
    () => ({
      important_information: serviceID,
      pension_type: threeA?.pension_type || '',
      financial_institution: threeA?.financial_institution || '',
      storage_location: threeA?.storage_location || '',
    }),
    [threeA, watch('important_information')]
  );

  const methods = useForm<ThreeA>({
    // @ts-ignore
    resolver: yupResolver(threeASchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isDirty },
  } = methods;

  const disableSubmit = !isDirty;

  const { reset } = methods;

  const { translate } = useLocales();

  const onSubmit = async (data: ThreeA) => {
    if (editMode) {
      await updateThreeA(data).then(() => {
        resetEditMode?.();
        invalidateImportantInformationQueries.get3AById(Number(editMode));
        invalidateImportantInformationQueries.get3A();
      });
    } else {
      await addThreeA(data).then(() => {
        resetAddMore?.();
        invalidateImportantInformationQueries.get3A();
      });
    }
  };

  useEffect(() => {
    if (threeA || serviceID) {
      reset(defaultValues);
    }
  }, [threeA, serviceID]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack
        direction={{
          xs: 'column',
          sm: 'row',
        }}
        spacing={2}
      >
        <RHFSelect
          name="pension_type"
          label={String(translate('global.formLabels.type'))}
        >
          {threeATypes.map((type) => (
            <MenuItem key={type.value} value={type.value}>
              {String(
                translate(`importantInformation.threeATypes.${type.value}`)
              )}
            </MenuItem>
          ))}
        </RHFSelect>

        <RHFTextField
          name="financial_institution"
          label={String(translate('global.formLabels.financialInstitution'))}
        />
        <RHFTextField
          name="storage_location"
          label={String(translate('global.formLabels.storageLocation'))}
        />
      </Stack>
      <Stack
        direction={{
          xs: 'column',
          sm: 'row',
        }}
        spacing={2}
        justifyContent="flex-end"
        sx={{ mt: 2 }}
      >
        <Button
          variant="contained"
          color="inherit"
          onClick={() => {
            if (editMode) {
              resetEditMode?.();
            } else {
              resetAddMore?.();
            }
          }}
        >
          <Typography variant="subtitle2" fontWeight="light">
            {String(translate('global.cancel'))}
          </Typography>
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          disabled={disableSubmit}
          loading={adding || updating}
          onClick={(e) => {
            e.preventDefault();
            handleSubmit(onSubmit)();
          }}
        >
          <Typography variant="subtitle2" fontWeight="light">
            {String(translate('global.save'))}
          </Typography>
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
