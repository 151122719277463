import { Button, Stack, Typography, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React from 'react';
import Iconify from '#/components/shared/ui/Iconify';
import useLocales from '#/hooks/useLocales';
import { ThreeA } from '#/types/importantInformation';
import {
  invalidateImportantInformationQueries,
  useDelete3A,
} from '#/api/importantInformation';
import ConfirmDeleteModal from '#/components/pages/ImportantInformation/steps/FinancialInformations/3a/confirm-delete-modal';

type Props = {
  threeA: ThreeA;
  handleEditMode: (id: number) => void;
  readOnly?: boolean;
};

export default function ThreeADetails({
  threeA,
  handleEditMode,
  readOnly,
}: Props) {
  const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean>(false);

  const handleOpenDeleteModal = () => setOpenDeleteModal(true);

  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const { translate } = useLocales();

  const { mutateAsync: deleteThreeA, isLoading: deleting } = useDelete3A(
    threeA.id
  );

  const threeATypeLabel = String(
    translate(`importantInformation.threeATypes.${threeA.pension_type}`)
  );

  const handleDelete = async () => {
    await deleteThreeA(threeA.id).then(() => {
      invalidateImportantInformationQueries.get3A();
    });
    handleCloseDeleteModal();
  };

  return (
    <Box sx={{ my: 1 }}>
      <Stack
        direction={{
          xs: 'column',
          sm: 'row',
        }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack
          direction={{
            xs: 'column',
            sm: 'row',
          }}
          spacing={6}
        >
          <Typography
            variant="subtitle1"
            fontWeight="light"
            sx={{ width: '30px' }}
          >
            {threeATypeLabel}
          </Typography>
          <Typography
            variant="subtitle1"
            fontWeight="light"
            sx={{ width: '80px' }}
          >
            {threeA.financial_institution}
          </Typography>
          <Typography variant="subtitle1" fontWeight="light">
            {threeA.storage_location}
          </Typography>
        </Stack>

        {!readOnly && (
          <Stack direction="row" spacing={1}>
            <Button
              size="small"
              variant="outlined"
              startIcon={
                <Iconify icon="octicon:pencil-16" sx={{ fontSize: '1.5rem' }} />
              }
              onClick={() => handleEditMode(threeA.id)}
            >
              <Typography variant="subtitle2" fontWeight="light">
                {String(translate('global.edit'))}
              </Typography>
            </Button>
            <LoadingButton
              loading={deleting}
              size="small"
              variant="outlined"
              color="error"
              startIcon={
                <Iconify icon="octicon:trash-16" sx={{ fontSize: '1.5rem' }} />
              }
              onClick={handleOpenDeleteModal}
            >
              <Typography variant="subtitle2" fontWeight="light">
                {String(translate('global.delete'))}
              </Typography>
            </LoadingButton>
          </Stack>
        )}
      </Stack>
      <ConfirmDeleteModal
        open={openDeleteModal}
        closeDialog={handleCloseDeleteModal}
        onConfirm={handleDelete}
        financial_institution={threeA.financial_institution}
      />
    </Box>
  );
}
