import IdentificationDocumentsForm from '#/components/pages/ImportantInformation/steps/IdentificationDocuments/identification-documents-form';
import FinancialInformation from '#/components/pages/ImportantInformation/steps/FinancialInformations/financial-information';
import BankDocuments from '#/components/pages/ImportantInformation/steps/FinancialInformations/bank-documents';
import InsurancePolicies from '#/components/pages/ImportantInformation/steps/FinancialInformations/insurance-policies/insurance-policies';
import StatePension from '#/components/pages/ImportantInformation/steps/FinancialInformations/state-pension';
import PensionFund from '#/components/pages/ImportantInformation/steps/FinancialInformations/pension-fund';
import ThreeA from '#/components/pages/ImportantInformation/steps/FinancialInformations/3a/3a';
import TrustedPerson from '#/components/pages/ImportantInformation/steps/TrustedPerson/trusted-person';
import TrustedPersonForm from '#/components/pages/ImportantInformation/steps/TrustedPerson/trusted-person-form';
import Notary from '#/components/pages/ImportantInformation/steps/TrustedPerson/notary/notary';

export const stepsData = [
  {
    content: () => <IdentificationDocumentsForm />,
  },
  {
    content: () => <FinancialInformation />,
  },
  {
    content: () => <TrustedPerson />,
  },
];

export const financialInformationQuestions = [
  {
    id: 1,
    questionKey: 'bank_documents_location',
    children: {
      content: () => <BankDocuments />,
    },
  },
  {
    id: 2,
    questionKey: 'insurance_policies',
    children: {
      content: () => <InsurancePolicies />,
    },
  },
  {
    id: 3,
    questionKey: 'state_pension',
    children: {
      content: () => <StatePension />,
    },
  },
  {
    id: 4,
    questionKey: 'pension_fund',
    children: {
      content: () => <PensionFund />,
    },
  },
  {
    id: 5,
    questionKey: '3a',
    children: {
      content: () => <ThreeA />,
    },
  },
];

export const trustedPersonQuestions = [
  {
    id: 1,
    questionKey: 'trusted_person',
    children: {
      content: () => <TrustedPersonForm />,
    },
  },
  {
    id: 2,
    questionKey: 'notary',
    children: {
      content: () => <Notary />,
    },
  },
];
